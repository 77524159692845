<template>
    <div>
        <el-tabs v-model="tabValue" type="card" closable @tab-remove="removeTab">
            <el-tab-pane name="temperature">
                <span slot="label">Temperature</span>
                <div class="item-container">
                    <span style="margin-left: 5px">{{$t('accessory.modbus.device-address')}}</span>
                    <div class="mode-container">
                        <el-form-item style="width: 410px" prop="details.device.unitId" :label="$t('accessory.modbus.device-address')">
                            <el-input min="0" type="number" v-model="accessoryData.details.device.unitId"/>
                        </el-form-item>            
                    </div>
                </div>

                <div class="item-container">
                    <span style="margin-left: 5px">{{$t('accessory.modbus.on-off-state')}}</span>
                    <div class="mode-container">

                        <el-form-item prop="details.device.actions.setOn.addressType" :label="$t('accessory.modbus.address-type')">
                            <el-select v-model="onOffAddressType" @change="onSelectSetOnAddressType">
                                <el-option v-for="addressType in thermostatOnOffAddressTypeList" :label="addressType.title" :value="addressType.value" :key="addressType.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item style="width: 410px" prop="details.device.actions.setOn.address" :label="$t('accessory.modbus.register-address')">
                            <el-input type="number" min="0" @change="onRegisterAddressChanged" v-model="accessoryData.details.device.actions.setOn.address"/>
                        </el-form-item>   

                        <el-form-item v-show="onOffAddressType == ADDRESS_TYPE_HOLDING_REGISTER" style="width: 410px" prop="details.device.actions.setOn.onValue" :label="$t('accessory.modbus.on-value')">
                            <el-input type="number" min="0" @change="onOnValueChanged" v-model="accessoryData.details.device.actions.setOn.onValue"/>
                        </el-form-item>

                        <el-form-item v-show="onOffAddressType == ADDRESS_TYPE_HOLDING_REGISTER" style="width: 410px" prop="details.device.actions.setOn.offValue" :label="$t('accessory.modbus.off-value')">
                            <el-input type="number" min="0" @change="onOffValueChanged" v-model="accessoryData.details.device.actions.setOn.offValue"/>
                        </el-form-item>      

                        <el-form-item prop="coilOnValue" v-show="onOffAddressType == ADDRESS_TYPE_COIL" style="width: 410px" :label="$t('accessory.modbus.on-value')">
                            <el-input type="number" min="0" v-model="coilOnValue" @change="onOnValueChanged" />
                        </el-form-item>                                  
                    </div>
                </div>        

                <div class="item-container">
                    <span style="margin-left: 5px">{{$t('accessory.modbus.room-temperature')}}</span>
                    <div class="mode-container">

                        <el-form-item prop="details.device.statuses.getTemperature.addressType" :label="$t('accessory.modbus.address-type')">
                            <el-select v-model="temperatureAddressType" @change="onSelectTemperatureAddressType">
                                <el-option v-for="addressType in thermostatTemperatureAddressTypeList" :label="addressType.title" :value="addressType.value" :key="addressType.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item style="width: 410px" :label="$t('accessory.modbus.register-address')">
                            <el-input type="number" v-model="accessoryData.details.device.statuses.getTemperature.address"/>
                        </el-form-item>  

                        <el-form-item style="width: 410px" :label="$t('accessory.modbus.factor')">
                            <el-input type="number" v-model="accessoryData.details.device.statuses.getTemperature.factor"/>
                        </el-form-item>              
                    
                    </div>
                </div>

                <div class="item-container">
                    <span style="margin-left: 5px">{{$t('accessory.modbus.set-temperature')}}</span>
                    <div class="mode-container">
                        <el-form-item style="width: 410px" :label="$t('accessory.modbus.register-address')">
                            <el-input type="number" @change="onSetTargetPointChaged" v-model="accessoryData.details.device.actions.setTargetPoint.address"/>
                        </el-form-item>      
                        <el-form-item style="width: 410px" :label="$t('accessory.modbus.factor')">
                            <el-input @change="onSetTargetPointFactorChanged" type="number" v-model="accessoryData.details.device.actions.setTargetPoint.factor"/>
                        </el-form-item>                       
                    </div>
                </div>                 
            </el-tab-pane>

            <el-tab-pane v-for="component in accessoryData.details.device.component" :key="component.type" :name="component.type">
                <span slot="label">{{ titleTab(component.type) }}</span>

                <!--Component Title-->
                <span style="margin-left: 15px">
                    Title
                    <el-input style="width: 160px; margin-left: 10px;margin-top: 10px" size="small" type="text" v-model="component.title" placeholder="Title"></el-input>
                </span>

                <div v-for="(mode, index) in components.find(comp => comp.type === component.type).modes" :key="mode.type" class="item-container">
                
                    <el-checkbox v-model="mode.selected" style="margin-left: 5px" @change="setComponent(component)">
                        {{ mode.title }}
                    </el-checkbox>

                    <div class="mode-container" v-if="mode.selected">

                        <span style="display: flex; flex-direction: row; align-items: center">
                        Title
                        <!--Mode Icon-->
                        <el-popover v-if="false" placement="right" v-model="visibleIcons[mode.type]">

                            <div v-for="(row, index) in modeIcons" :key="index" style="display: flex; flex-direction: row">
                            <div v-for="(icon, index) in row" :key="icon.value" style="display: flex; flex-direction: column">
                                <span @click="onSelectIcon(component.type, mode.type, icon)">
                                <img :src="'/assets/icons/' + icon.value + '.png'" class="selectable-icon">
                                </span>

                            </div>
                            </div>

                            <img slot="reference" :src="'/assets/icons/' + iconMode(component.type, mode.type) + '.png'" class="icon-contact">
                        </el-popover>

                        <image-picker :images="allIcons[component.type]" :selected-image="iconMode(component.type, mode.type)" placement="right" @selectImage="onSelectImage(component.type, mode.type, $event)"></image-picker>

                        <!--Mode Title-->
                        <el-input style="width: 160px; margin-left: 10px;" size="mini" type="text"
                                    :value="titleTabContent(component.type, mode.type)" @input="onChangeModeName($event, component.type, mode.type)" placeholder="Title">
                        </el-input>

                        <el-button size="mini" style="margin-left: 10px" @click="applyAll(component, mode)"><i class="fa fa-copy"></i></el-button>                        
                        </span>                         

                        <el-form-item style="width: 410px" label="Data Type">
                            <el-select v-model="mode.actions['setMode'].addressType" @change="setComponent(component)">
                                <el-option v-for="addressType in modeAddressTypes" :label="addressType.title" :value="addressType.value" :key="addressType.value">
                                </el-option>
                            </el-select>                            
                        </el-form-item>

                        <el-form-item style="width: 410px" label="Register Address">
                            <el-input type="number" v-model="mode.actions['setMode'].address" @change="setComponent(component)"/>                          
                        </el-form-item>   

                        <el-form-item v-show="mode.actions['setMode'].addressType == 1" style="width: 410px" label="Active Value">
                            <el-input type="number" v-model="mode.actions['setMode'].statusValue" @change="setComponent(component)"/>
                        </el-form-item> 

                        <el-form-item v-show="mode.actions['setMode'].addressType == 0" style="width: 410px" label="Active Value">
                            <el-input type="number" min="0" max="1" v-model="mode.actions['setMode'].statusValue" @change="onAddressTypeChanged(component,mode, $event)"/>
                        </el-form-item> 
                    </div>
                </div>
            </el-tab-pane>

            <el-tab-pane v-if="addableTabs.length > 0" name="addTab">

                <el-popover title="Select Mode" width="300" slot="label" placement="top" v-model="visibleAddTab">

                    <el-table :data="addableTabs" :show-header="false">

                        <el-table-column prop="title" width="200"></el-table-column>

                        <el-table-column>
                        <template slot-scope="scope">
                            <el-button size="mini" @click="addTab(scope.row)">Select</el-button>
                        </template>
                        </el-table-column>

                    </el-table>

                    <span slot="reference"> <i class="fa fa-plus" style="margin: 10px"></i> </span>

                </el-popover>

            </el-tab-pane>            
        </el-tabs>
    </div>
</template>

<script>
    import {ADDRESS_TYPE_COIL, ADDRESS_TYPE_HOLDING_REGISTER, thermostatOnOffAddressTypeList, thermostatTemperatureAddressTypeList} from "@/utils/modbus";
    import ImagePicker from '../ImagePicker'
    export default {
        name: "ModbusAdvancedThermostatInputs",

        data: function(){
            return {
                visibleAddTab:false,
                coilOnValue:1,
                ADDRESS_TYPE_HOLDING_REGISTER,
                ADDRESS_TYPE_COIL,
                tabValue: "temperature",
                onOffAddressType: ADDRESS_TYPE_COIL,
                temperatureAddressType: ADDRESS_TYPE_HOLDING_REGISTER,
                minSetPoint: 5,
                maxSetPoint: 40,
                setPointStep: 1,
                visibleIcons: {},
                tabCount: 1,
                allIcons: {
                    modeOpp: ['ic_operation_auto', 'ic_operation_heat', 'ic_operation_cool', 'ic_operation_fan', 'ic_operation_dry'],
                    modeControl: ['ic_control_auto', 'ic_control_comfort', 'ic_control_standby', 'ic_control_night', 'ic_control_protection'],
                    modeFan: ['ic_fan_auto', 'ic_fan_1', 'ic_fan_2', 'ic_fan_3', 'ic_fan_4', 'ic_fan_5'],
                },                
                components:[
                    { title: "Operation Mode", type: "modeOpp", modes: [
                        { title: "Auto", type: "modeOppAuto", icon: "ic_operation_auto", selected: true, 
                            actions:
                                {
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                                }, 
                            statuses:{
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                            }},
                        { title: "Heat", type: "modeOppHeat", icon: "ic_operation_heat", selected: true, 
                            actions:
                                {
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                                }, 
                            statuses:{
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                            }          },
                        { title: "Cool", type: "modeOppCool", icon: "ic_operation_cool", selected: true, 
                            actions:
                                {
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                                }, 
                            statuses:{
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                            } },
                        { title: "Fan", type: "modeOppFan", icon: "ic_operation_fan", selected: true, 
                            actions:
                                {
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                                }, 
                            statuses:{
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                            }          },
                        { title: "Dry", type: "modeOppDry", icon: "ic_operation_dry", selected: true, 
                            actions:
                                {
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                                }, 
                            statuses:{
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                            }          }]},
                    { title: "Control Mode", type: "modeControl", modes:[
                        { title: "Auto", type: "modeControlAuto", icon: "ic_control_auto", selected: true, 
                            actions:
                                {
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                                }, 
                            statuses:{
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                            }          },
                        { title: "Comfort", type: "modeControlComfort", icon: "ic_control_comfort", selected: true, 
                            actions:
                                {
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                                }, 
                            statuses:{
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                            }          },
                        { title: "Standby", type: "modeControlStandby", icon: "ic_control_standby", selected: true, 
                            actions:
                                {
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                                }, 
                            statuses:{
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                            }          },
                        { title: "Night", type: "modeControlNight", icon: "ic_control_night", selected: true, 
                            actions:
                                {
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                                }, 
                            statuses:{
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                            }          },
                        { title: "Protection", type: "modeControlProtection", icon: "ic_control_protection", selected: true, 
                            actions:
                                {
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                                }, 
                            statuses:{
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                            }          },]},
                    { title: "Fan Mode", type: "modeFan", modes: [
                        { title: "Auto", type: "modeFanAuto", icon: "ic_fan_auto", selected: true, 
                            actions:
                                {
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                                }, 
                            statuses:{
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                            }          },
                        { title: "Lowest", type: "modeFanLowest", icon: "ic_fan_1", selected: true, 
                            actions:
                                {
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                                }, 
                            statuses:{
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                            }          },
                        { title: "Lower", type: "modeFanLower", icon: "ic_fan_2", selected: true, 
                            actions:
                                {
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                                }, 
                            statuses:{
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                            }          },
                        { title: "Middle", type: "modeFanMiddle", icon: "ic_fan_3", selected: true, 
                            actions:
                                {
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                                }, 
                            statuses:{
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                            }          },
                        { title: "Higher", type: "modeFanHigher", icon: "ic_fan_4", selected: true, 
                            actions:
                                {
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                                }, 
                            statuses:{
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                            }          },
                        { title: "Highest", type: "modeFanHighest", icon: "ic_fan_5", selected: true, 
                            actions:
                                {
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                                }, 
                            statuses:{
                                    setMode:{
                                            operationName:"HHModbusStatus",
                                            address:0,
                                            addressType:0,
                                            statusValue:0
                                        }
                            }          },]},
                ]                
            }
        },

        components: {
            ImagePicker
        },        

        computed: {
            modeIcons: function () {
            var modeIcons = new Array()
            var row = new Array()


            this.icons.forEach((icon, index) => {

                if (row.length == 5) {
                modeIcons.push(row)
                row = new Array()
                } else {
                row.push(icon)
                }
            })

            return modeIcons
            },            
            modeAddressTypes: function(){
                return [{title:"Coil", value:ADDRESS_TYPE_COIL}, {title:"Holding Register", value:ADDRESS_TYPE_HOLDING_REGISTER}]
            },          
            thermostatOnOffAddressTypeList(){
                return thermostatOnOffAddressTypeList
            },

            thermostatTemperatureAddressTypeList(){
                return thermostatTemperatureAddressTypeList
            },
            addableTabs: function () {
                this.tabCount
                var addableTabs = []
                this.components.forEach(component => {
                let tab = this.accessoryData.details.device.component.find(tab => tab.type == component.type)
                    if (!tab) {
                        addableTabs.push(JSON.parse(JSON.stringify(component)))
                    }
                })

                return addableTabs
            },                    
        }, 
    
        props: {
            accessoryData: {}
        },     

        methods:{

            applyAll(component, mode){

                component.modes.forEach(m => {
                    if(m != mode){                        
                        for(let pName in mode.actions){
                            if(m.actions[pName]){
                                m.actions[pName].address = mode.actions[pName].address;
                                m.actions[pName].addressType = mode.actions[pName].addressType;
                            }
                        }

                        for(let pName in mode.statuses){
                            if(m.statuses[pName]){
                                m.statuses[pName].address = mode.statuses[pName].address;
                                m.statuses[pName].addressType = mode.statuses[pName].addressType;
                            }
                        }
                    }
                });
                
                this.setComponent(component)
            },

            onAddressTypeChanged(component, mode, value){
                
                if(value > 1){
                    mode.actions['setMode'].statusValue = 1
                }
                else if(value < 0){
                    mode.actions['setMode'].statusValue = 0
                }

                this.setComponent(component)
            },
        
            onChangeModeName: function(val, componentType, modeType){
                let mode = this.accessoryData.details.device.component.find(comp => comp.type === componentType).modes.find(mode => mode.type === modeType)
                mode.title = val
            },            
            titleTabContent: function(componentType, modeType){
                return this.accessoryData.details.device.component.find(comp => comp.type === componentType).modes.find(mode => mode.type === modeType).title
            },            
            iconMode: function(componentType, modeType){
                return this.accessoryData.details.device.component.find(comp => comp.type === componentType).modes.find(mode => mode.type === modeType).icon
            },           
            onSelectImage: function(compType, modeType, icon){
                let mode = this.accessoryData.details.device.component.find(comp => comp.type === compType).modes.find(mode => mode.type === modeType)
                mode.icon = icon
            },             
            onSelectIcon: function(compType, modeType, icon){
                let mode = this.accessoryData.details.device.component.find(comp => comp.type === compType).modes.find(mode => mode.type === modeType)
                mode.icon = icon.value
                this.visibleIcons[modeType] = false
            },            
            setComponent(component){
                component.modes.forEach(mode => {
                    mode.statuses['setMode'] = mode.actions['setMode']
                })
                this.accessoryData.details.device.component.find(comp => comp.type === component.type).modes = this.components.find(comp => comp.type === component.type).modes.filter(m=>m.selected);
            },
            addTab(component){
                this.tabCount++
                let tab = {...component}
                this.accessoryData.details.device.component.push(tab)     
                this.tabValue = tab.type           
                this.visibleAddTab = false;                        
            },            
            removeTab(component){
                this.tabCount--;
                let tab = {...component}
                this.accessoryData.details.device.component.splice(this.accessoryData.details.device.component.indexOf(tab), 1)   
                this.tabValue = "temperature"  
            },

            titleTab: function (type) {
                return this.components.find(component => component.type === type).title
            },

            updateSetPoints: function(){
                let setPoints = []
                let min = parseInt(this.minSetPoint)
                let max = parseInt(this.maxSetPoint)
                let step = parseFloat(this.setPointStep)

                if (max <= min || step <= 0)
                return

                for (var i = min; i <= max; i += step) {
                setPoints.push(i)
                }

                this.accessoryData.targetSetPoints = setPoints
            },         
            
            onRegisterAddressChanged(value){
                this.accessoryData.details.device.statuses.setOn.address = value
            },

            onOnValueChanged(value){
                if(this.accessoryData.details.device.statuses.setOn.addressType == ADDRESS_TYPE_HOLDING_REGISTER){
                    this.accessoryData.details.device.statuses.setOn.onValue = value
                    this.coilOnValue = 1
                }
                else if(this.accessoryData.details.device.actions.setOn.addressType == ADDRESS_TYPE_COIL){
                    if(value < 0)
                        value = 0
                    else if(value > 1)
                        value = 1

                    let onValue = value
                    let offValue = onValue == 1 ? 0 : 1
                    
                    this.accessoryData.details.device.actions.setOn.onValue = onValue
                    this.accessoryData.details.device.actions.setOn.offValue = offValue;

                    this.accessoryData.details.device.statuses.setOn.onValue = onValue
                    this.accessoryData.details.device.statuses.setOn.offValue = offValue;     
                    
                    this.coilOnValue = onValue
                }                    
            },    
            
            onOffValueChanged(value){
                if(this.accessoryData.details.device.statuses.setOn.addressType == ADDRESS_TYPE_HOLDING_REGISTER)
                    this.accessoryData.details.device.statuses.setOn.offValue = value
            },                

            onSetTargetPointFactorChanged(value){
                this.accessoryData.details.device.statuses.setTargetPoint.factor = value
            },

            onSetTargetPointChaged(value){
                this.accessoryData.details.device.statuses.setTargetPoint.address = value
            },

            onSelectTemperatureAddressType(value){
                this.accessoryData.details.device.statuses.getTemperature.addressType = value
            },

            onSelectSetOnAddressType(value){
                this.accessoryData.details.device.actions.setOn.addressType = value
                this.accessoryData.details.device.statuses.setOn.addressType = value

                if(value == ADDRESS_TYPE_COIL){
                    this.accessoryData.details.device.actions.setOn.onValue = 1
                    this.accessoryData.details.device.actions.setOn.offValue = 0

                    this.accessoryData.details.device.statuses.setOn.onValue = 1
                    this.accessoryData.details.device.statuses.setOn.offValue = 0
                }
                else{
                    this.accessoryData.details.device.actions.setOn.onValue = 1 
                    this.accessoryData.details.device.actions.setOn.offValue = 0

                    this.accessoryData.details.device.statuses.setOn.onValue = 1
                    this.accessoryData.details.device.statuses.setOn.offValue = 0
                }
            }
        },     
        created(){

            this.updateSetPoints()

            if(!this.accessoryData.details.device){
                this.accessoryData.details.device = {}
                this.accessoryData.details.device.component = []

                this.accessoryData.details.device.unitId = 1

                this.accessoryData.details.device.actions = {}
                this.accessoryData.details.device.actions.setOn = {}
                this.accessoryData.details.device.actions.setTargetPoint = {}

                this.accessoryData.details.device.actions.setOn.address = 1
                this.accessoryData.details.device.actions.setOn.addressType = 0

                this.accessoryData.details.device.actions.setOn.onValue = 0
                this.accessoryData.details.device.actions.setOn.offValue = 0

                this.accessoryData.details.device.statuses = {}
                this.accessoryData.details.device.statuses.setOn = {}
                this.accessoryData.details.device.statuses.getTemperature = {};
                this.accessoryData.details.device.statuses.setTargetPoint = {};
                this.accessoryData.details.device.statuses.setOn.address = 1
                this.accessoryData.details.device.statuses.setOn.addressType = 0

                this.accessoryData.details.device.statuses.setOn.onValue = 1
                this.accessoryData.details.device.statuses.setOn.offValue = 0
            
                this.accessoryData.details.device.statuses.getTemperature.addressType = ADDRESS_TYPE_HOLDING_REGISTER;
                this.accessoryData.details.device.statuses.getTemperature.address = 1;
                this.accessoryData.details.device.statuses.getTemperature.factor = 0;

                this.accessoryData.details.device.actions.setTargetPoint.addressType = ADDRESS_TYPE_HOLDING_REGISTER;
                this.accessoryData.details.device.actions.setTargetPoint.address = 1;
                this.accessoryData.details.device.actions.setTargetPoint.factor = 0;

                this.accessoryData.details.device.statuses.setTargetPoint.addressType = ADDRESS_TYPE_HOLDING_REGISTER;
                this.accessoryData.details.device.statuses.setTargetPoint.address = 1;
                this.accessoryData.details.device.statuses.setTargetPoint.factor = 0;

                this.accessoryData.details.device.actions.setOn.operationName = "HHModbusOnOff"
                this.accessoryData.details.device.statuses.setOn.operationName = "HHModbusOnOff"

                this.accessoryData.details.device.statuses.getTemperature.operationName = "HHModbusFractional"

                this.accessoryData.details.device.actions.setTargetPoint.operationName = "HHModbusFractional"
                this.accessoryData.details.device.statuses.setTargetPoint.operationName = "HHModbusFractional"
            }
            else{
                this.onOffAddressType = this.accessoryData.details.device.actions.setOn.addressType
                this.temperatureAddressType = this.accessoryData.details.device.statuses.getTemperature.addressType

                if(this.onOffAddressType == ADDRESS_TYPE_COIL){
                    this.coilOnValue = this.accessoryData.details.device.actions.setOn.onValue
                }

                if(this.accessoryData.details.device.component){
                    this.accessoryData.details.device.component.forEach(accessoryComponent =>{
                        const cComponent = this.components.find(cComponent => cComponent.type === accessoryComponent.type)
                        cComponent.title = accessoryComponent.title
                        cComponent.modes.forEach(cMode =>{
                            const accessoryMode = accessoryComponent.modes.find(m=> m.type == cMode.type)
                            if(accessoryMode){
                                let index = cComponent.modes.indexOf(cMode)
                                cComponent.modes[index] = accessoryMode
                            }
                            else{
                                cMode.selected = false
                            }
                        })
                    })
                }

            }
        }                 
    }
</script>

<style scoped>
  .mode-container {
    border: 1px solid #eeeeee;
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;
  }

  .item-container {
    margin-top: 20px;
    padding: 15px
  }
  .icon-contact {
    filter: brightness(0.45);
    width: 24px;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
  }

  .selectable-icon {
    filter: brightness(0.45);
    width: 24px;
    height: 24px;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    border: 1px solid #eeeeee;
  }

  .selectable-icon:hover {
    filter: brightness(0.65);
  }  
</style>