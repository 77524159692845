var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      ref: "picker",
      attrs: {
        placement: _vm.placement,
        width: _vm.width,
        trigger: _vm.trigger
      },
      model: {
        value: _vm.visible,
        callback: function($$v) {
          _vm.visible = $$v
        },
        expression: "visible"
      }
    },
    [
      _vm._l(_vm.imageList, function(row, index) {
        return _c(
          "el-row",
          { key: index },
          _vm._l(row, function(image, index) {
            return _c(
              "el-col",
              { key: index, attrs: { span: 24 / _vm.columns } },
              [
                _c(
                  "span",
                  {
                    staticClass: "icon-item-layout",
                    on: {
                      click: function($event) {
                        return _vm.onSelectImage(image)
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "icon-item",
                      attrs: { src: "/assets/icons/" + image + ".png" },
                      on: { error: _vm.onImageError }
                    })
                  ]
                )
              ]
            )
          }),
          1
        )
      }),
      _c("span", { attrs: { slot: "reference" }, slot: "reference" }, [
        _c("img", {
          staticClass: "icon",
          attrs: { src: "/assets/icons/" + _vm.currentImage + ".png" },
          on: { error: _vm.onImageError }
        })
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }