<template>
  <div>

    <el-form-item prop="details.io" :label="$t('accessory.output-number')" :rules="[{required: true, message: $t('accessory.form-rules.select-output')}]">
      <el-select v-model="accessoryData.details.io" :placeholder="$t('accessory.select-output')">
        <el-option v-for="index in outputs" :label="'Q' + (index + 1) + '-' + 'Q' + (index + 2)" :value="(8192 + index)" :key="index + ''">
        </el-option>
      </el-select>
    </el-form-item>

  </div>
</template>

<script>
  import {outputs} from "@/utils/logo";

  export default {
    name: "LogoShutterInputs",
    data: function(){
      return {

      }
    },

    computed: {
      outputs(){
        return [0, 2, 4, 6, 8, 10, 12, 14, 16, 18]
      }
    },

    props: {
      accessoryData: {}
    }
  }
</script>

<style scoped>

</style>