import {
  ACCESSORY_CATEGORY_AIR_CONDITIONER,
  ACCESSORY_CATEGORY_DIMMER,
  ACCESSORY_CATEGORY_TUNABLE_WHITE,
  ACCESSORY_CATEGORY_SHUTTER,
  ACCESSORY_CATEGORY_SWITCH,
  ACCESSORY_CATEGORY_PUNCH_BUTTON,
  ACCESSORY_CATEGORY_BINARY_BUTTON,
  categoryNames,
  ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT,
  ACCESSORY_CATEGORY_SENSOR_DOOR,
  ACCESSORY_CATEGORY_SENSOR_GAS,
  ACCESSORY_CATEGORY_SENSOR_LEAK,
  ACCESSORY_CATEGORY_SENSOR_MOTION,
  ACCESSORY_CATEGORY_SENSOR_SMOKE,
  ACCESSORY_CATEGORY_SENSOR_WINDOW,
  ACCESSORY_CATEGORY_STATUS_DISPLAY,
  ACCESSORY_CATEGORY_RGBW,
  ACCESSORY_CATEGORY_AUDIO,
  ACCESSORY_CATEGORY_SIREN,
} from "./accessory-utils";

export const knxCategoryList = [
  ACCESSORY_CATEGORY_STATUS_DISPLAY,
  ACCESSORY_CATEGORY_PUNCH_BUTTON,
  ACCESSORY_CATEGORY_BINARY_BUTTON,
  ACCESSORY_CATEGORY_SWITCH,
  ACCESSORY_CATEGORY_DIMMER,
  ACCESSORY_CATEGORY_TUNABLE_WHITE,
  ACCESSORY_CATEGORY_RGBW,
  ACCESSORY_CATEGORY_SHUTTER,
  ACCESSORY_CATEGORY_AIR_CONDITIONER,
  ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT,
  ACCESSORY_CATEGORY_AUDIO,
  ACCESSORY_CATEGORY_SENSOR_DOOR,
  ACCESSORY_CATEGORY_SENSOR_GAS,
  ACCESSORY_CATEGORY_SENSOR_LEAK,
  ACCESSORY_CATEGORY_SENSOR_MOTION,
  ACCESSORY_CATEGORY_SENSOR_SMOKE,
  ACCESSORY_CATEGORY_SENSOR_WINDOW,
  ACCESSORY_CATEGORY_SIREN,
];

export function knxCategories() {
  return categoryNames.filter((category) =>
    knxCategoryList.includes(category.value)
  );
}

const knxGroupAddressFormat = /^(3[0-1]|2[0-9]|1[0-9]|[0-9])\/([0-7])\/(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export function validateKnxGroupAddress(knxGroupAddress) {
  if (knxGroupAddress.match(knxGroupAddressFormat)) {
    return true;
  } else {
    return false;
  }
}
