<template>
  <div :style="{marginLeft: editMode ? (210 + 'px') : (0 + 'px')}">

    <div class="form-item" v-for="(address, index) in accessoryData.details.knxAddresses" :key="index">

      <KnxGroupAddress @changeKnxAddress="onChangeKnxField" :address="address" :enable-write-address="false" :enable-write-value="false"></KnxGroupAddress>

    </div>


    <div style="margin: 10px; padding: 10px">

      <el-form-item prop="trippedValue" :label="$t('accessory.normally')" style="width: 400px">
        <el-switch v-model="trippedValue" :active-value="true" :inactive-value="false"></el-switch>
      </el-form-item>

      <el-form-item v-if="!isSuddenSensor()" prop="entryDelay" :label="$t('accessory.entry-delay')" style="width: 400px" :rules="[{required: true, message: $t('accessory.form-rules.enter-entry-delay')}]">
        <el-input type="number" v-model="accessoryData.entryDelay" :placeholder="$t('accessory.enter-entry-delay')">
        </el-input>
      </el-form-item>

      <el-form-item v-if="!isSuddenSensor()" prop="armStateInAwayMode" :label="$t('accessory.away')" style="width: 400px">
        <el-switch v-model="armStateInAwayMode"></el-switch>
      </el-form-item>

      <el-form-item v-if="!isSuddenSensor()" prop="armStateInStayMode" :label="$t('accessory.stay')" style="width: 400px">
        <el-switch v-model="armStateInStayMode"></el-switch>
      </el-form-item>
    </div>

  </div>
</template>


<script>
  import {knxMixin} from "./knxMixin";
  import KnxGroupAddress from "./KnxGroupAddress"
  import {
    ACCESSORY_CATEGORY_SENSOR_DOOR,
    ACCESSORY_CATEGORY_SENSOR_MOTION,
    ACCESSORY_CATEGORY_SENSOR_WINDOW
  } from "../../../../utils/accessory-utils";

  export default {
    name: "KnxSensorInputs",

    components: {
      KnxGroupAddress
    },

    mixins: [knxMixin],

    props: {
      accessoryData: {},
      editMode: {
        type: Boolean,
        default: false
      },
    },

    data: function(){

      return {
        data: {},
        temp: "",

        trippedValue: 'trippedValue' in this.accessoryData ? this.accessoryData.trippedValue : !this.isSuddenSensor(),
        armStateInAwayMode: 'armStateInAwayMode' in this.accessoryData ? this.accessoryData.armStateInAwayMode : true,
        armStateInStayMode: 'armStateInStayMode' in this.accessoryData ? this.accessoryData.armStateInStayMode : true,

        errorFields: {}
      }
    },

    watch: {
      trippedValue: function (value) {
        this.accessoryData.trippedValue = value
      },
      armStateInAwayMode: function (value) {
        this.accessoryData.armStateInAwayMode = value
      },
      armStateInStayMode: function (value) {
        this.accessoryData.armStateInStayMode = value
      },
    },

    methods: {
      onChange(address){
        address.readAddress = address.writeAddress
      },
      isSuddenSensor(){
        return ![ACCESSORY_CATEGORY_SENSOR_DOOR, ACCESSORY_CATEGORY_SENSOR_WINDOW, ACCESSORY_CATEGORY_SENSOR_MOTION].includes(this.accessoryData.category)
      },

      validate(){
        const result = this.accessoryData.details.knxAddresses.some(address => {
          return !this.errorFields.hasOwnProperty(address.variable) || this.errorFields[address.variable] == null
        })

        console.log(this.accessoryData.details.knxAddresses)
        console.log(this.errorFields)

        if (result) {
          this.$message({ type: "error", message: this.$t('accessory.form-rules.fill-all-empty-fields')})
          return false
        }

        var msg = []
        for (const [key, value] of Object.entries(this.errorFields)) {
          if (value)
            msg.push(value)
        }

        if (msg.length > 0) {
          const msgStr = msg.join("<br>")
          this.$message({ type: "error", message: msgStr, dangerouslyUseHTMLString: true})
          return false
        }

        return true
      },

      onChangeKnxField(variable, msg){
        console.log("onChangeKnxField", variable, msg)
        this.errorFields[variable] = msg
      }
    },

    created(){
      console.log("accessory", this.accessoryData)

      if (!this.accessoryData.details.knxAddresses) {
        this.accessoryData.details = {
          knxAddresses: [
            {
              variable: "tripState",
              dpt: "1.001",
              readAddress: "",
              activeValue: ""
            }
          ]
        }
      }

      this.accessoryData.armStateInDisarmMode = this.isSuddenSensor()

      if (!this.accessoryData.hasOwnProperty("trippedValue"))
        this.accessoryData.trippedValue = this.trippedValue

      if (!this.accessoryData.hasOwnProperty("armStateInAwayMode"))
        this.accessoryData.armStateInAwayMode = this.armStateInAwayMode
      if (!this.accessoryData.hasOwnProperty("armStateInStayMode"))
        this.accessoryData.armStateInStayMode = this.armStateInStayMode
    }
  }
</script>

<style scoped>
  .line {
    height: 1px;
    margin: 0px 0px 20px 0px;
    background-color: #eaeaea;
  }
  .form-item {
    margin: 10px;
    padding: 10px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    color: #606266;
  }
  .form-item span {
    font-weight: bold;
  }
</style>