import {
    ACCESSORY_CATEGORY_SWITCH,
    ACCESSORY_CATEGORY_SENSOR_MOTION,
    ACCESSORY_CATEGORY_SENSOR_DOOR,
    ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT,
    ACCESSORY_CATEGORY_SENSOR_WINDOW,
    ACCESSORY_CATEGORY_SENSOR_LEAK,
    ACCESSORY_CATEGORY_SENSOR_GAS,
    ACCESSORY_CATEGORY_SENSOR_SMOKE ,   
    categoryNames
  } from "./accessory-utils";
  
export const ADDRESS_TYPE_COIL = 0
export const ADDRESS_TYPE_HOLDING_REGISTER = 1
export const ADDRESS_TYPE_INPUT_REGISTER = 2
export const ADDRESS_TYPE_DISCRETE_INPUT = 3

export const modbusCategoryList = [ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT, ACCESSORY_CATEGORY_SWITCH, 
      ACCESSORY_CATEGORY_SENSOR_MOTION, ACCESSORY_CATEGORY_SENSOR_DOOR,ACCESSORY_CATEGORY_SENSOR_SMOKE,
      ACCESSORY_CATEGORY_SENSOR_WINDOW, ACCESSORY_CATEGORY_SENSOR_LEAK, ACCESSORY_CATEGORY_SENSOR_GAS]

export const switchAddressTypeList = [
        {title: "Coil", value: ADDRESS_TYPE_COIL},
        {title: "Holding Register", value: ADDRESS_TYPE_HOLDING_REGISTER}
    ]

export const thermostatOnOffAddressTypeList = [
  {title: "Coil", value: ADDRESS_TYPE_COIL},
  {title: "Holding Register", value: ADDRESS_TYPE_HOLDING_REGISTER}
]    

export const thermostatTemperatureAddressTypeList = [
  {title: "Holding Register", value: ADDRESS_TYPE_HOLDING_REGISTER},
  {title: "Input Register", value: ADDRESS_TYPE_INPUT_REGISTER}
]  

  export const sensorAddressTypeList = [
      {title: "Coil", value: ADDRESS_TYPE_COIL},
      {title: "Holding Register", value: ADDRESS_TYPE_HOLDING_REGISTER},
      {title: "Discrete Input", value: ADDRESS_TYPE_DISCRETE_INPUT},
      {title: "Input Register", value: ADDRESS_TYPE_INPUT_REGISTER}
  ]
  

  export function modbusCategories() {
    return categoryNames.filter(category => modbusCategoryList.includes(category.value))
  }