var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card", closable: "" },
          on: { "tab-remove": _vm.removeTab },
          model: {
            value: _vm.tabValue,
            callback: function($$v) {
              _vm.tabValue = $$v
            },
            expression: "tabValue"
          }
        },
        [
          _c("el-tab-pane", { attrs: { name: "temperature" } }, [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Temperature")
            ]),
            _c("div", { staticClass: "item-container" }, [
              _c("span", { staticStyle: { "margin-left": "5px" } }, [
                _vm._v(_vm._s(_vm.$t("accessory.modbus.device-address")))
              ]),
              _c(
                "div",
                { staticClass: "mode-container" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "410px" },
                      attrs: {
                        prop: "details.device.unitId",
                        label: _vm.$t("accessory.modbus.device-address")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { min: "0", type: "number" },
                        model: {
                          value: _vm.accessoryData.details.device.unitId,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.accessoryData.details.device,
                              "unitId",
                              $$v
                            )
                          },
                          expression: "accessoryData.details.device.unitId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "item-container" }, [
              _c("span", { staticStyle: { "margin-left": "5px" } }, [
                _vm._v(_vm._s(_vm.$t("accessory.modbus.on-off-state")))
              ]),
              _c(
                "div",
                { staticClass: "mode-container" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "details.device.actions.setOn.addressType",
                        label: _vm.$t("accessory.modbus.address-type")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          on: { change: _vm.onSelectSetOnAddressType },
                          model: {
                            value: _vm.onOffAddressType,
                            callback: function($$v) {
                              _vm.onOffAddressType = $$v
                            },
                            expression: "onOffAddressType"
                          }
                        },
                        _vm._l(_vm.thermostatOnOffAddressTypeList, function(
                          addressType
                        ) {
                          return _c("el-option", {
                            key: addressType.value,
                            attrs: {
                              label: addressType.title,
                              value: addressType.value
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "410px" },
                      attrs: {
                        prop: "details.device.actions.setOn.address",
                        label: _vm.$t("accessory.modbus.register-address")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "number", min: "0" },
                        on: { change: _vm.onRegisterAddressChanged },
                        model: {
                          value:
                            _vm.accessoryData.details.device.actions.setOn
                              .address,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.accessoryData.details.device.actions.setOn,
                              "address",
                              $$v
                            )
                          },
                          expression:
                            "accessoryData.details.device.actions.setOn.address"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.onOffAddressType ==
                            _vm.ADDRESS_TYPE_HOLDING_REGISTER,
                          expression:
                            "onOffAddressType == ADDRESS_TYPE_HOLDING_REGISTER"
                        }
                      ],
                      staticStyle: { width: "410px" },
                      attrs: {
                        prop: "details.device.actions.setOn.onValue",
                        label: _vm.$t("accessory.modbus.on-value")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "number", min: "0" },
                        on: { change: _vm.onOnValueChanged },
                        model: {
                          value:
                            _vm.accessoryData.details.device.actions.setOn
                              .onValue,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.accessoryData.details.device.actions.setOn,
                              "onValue",
                              $$v
                            )
                          },
                          expression:
                            "accessoryData.details.device.actions.setOn.onValue"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.onOffAddressType ==
                            _vm.ADDRESS_TYPE_HOLDING_REGISTER,
                          expression:
                            "onOffAddressType == ADDRESS_TYPE_HOLDING_REGISTER"
                        }
                      ],
                      staticStyle: { width: "410px" },
                      attrs: {
                        prop: "details.device.actions.setOn.offValue",
                        label: _vm.$t("accessory.modbus.off-value")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "number", min: "0" },
                        on: { change: _vm.onOffValueChanged },
                        model: {
                          value:
                            _vm.accessoryData.details.device.actions.setOn
                              .offValue,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.accessoryData.details.device.actions.setOn,
                              "offValue",
                              $$v
                            )
                          },
                          expression:
                            "accessoryData.details.device.actions.setOn.offValue"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.onOffAddressType == _vm.ADDRESS_TYPE_COIL,
                          expression: "onOffAddressType == ADDRESS_TYPE_COIL"
                        }
                      ],
                      staticStyle: { width: "410px" },
                      attrs: {
                        prop: "coilOnValue",
                        label: _vm.$t("accessory.modbus.on-value")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "number", min: "0" },
                        on: { change: _vm.onOnValueChanged },
                        model: {
                          value: _vm.coilOnValue,
                          callback: function($$v) {
                            _vm.coilOnValue = $$v
                          },
                          expression: "coilOnValue"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "item-container" }, [
              _c("span", { staticStyle: { "margin-left": "5px" } }, [
                _vm._v(_vm._s(_vm.$t("accessory.modbus.room-temperature")))
              ]),
              _c(
                "div",
                { staticClass: "mode-container" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop:
                          "details.device.statuses.getTemperature.addressType",
                        label: _vm.$t("accessory.modbus.address-type")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          on: { change: _vm.onSelectTemperatureAddressType },
                          model: {
                            value: _vm.temperatureAddressType,
                            callback: function($$v) {
                              _vm.temperatureAddressType = $$v
                            },
                            expression: "temperatureAddressType"
                          }
                        },
                        _vm._l(
                          _vm.thermostatTemperatureAddressTypeList,
                          function(addressType) {
                            return _c("el-option", {
                              key: addressType.value,
                              attrs: {
                                label: addressType.title,
                                value: addressType.value
                              }
                            })
                          }
                        ),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "410px" },
                      attrs: {
                        label: _vm.$t("accessory.modbus.register-address")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "number" },
                        model: {
                          value:
                            _vm.accessoryData.details.device.statuses
                              .getTemperature.address,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.accessoryData.details.device.statuses
                                .getTemperature,
                              "address",
                              $$v
                            )
                          },
                          expression:
                            "accessoryData.details.device.statuses.getTemperature.address"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "410px" },
                      attrs: { label: _vm.$t("accessory.modbus.factor") }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "number" },
                        model: {
                          value:
                            _vm.accessoryData.details.device.statuses
                              .getTemperature.factor,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.accessoryData.details.device.statuses
                                .getTemperature,
                              "factor",
                              $$v
                            )
                          },
                          expression:
                            "accessoryData.details.device.statuses.getTemperature.factor"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "item-container" }, [
              _c("span", { staticStyle: { "margin-left": "5px" } }, [
                _vm._v(_vm._s(_vm.$t("accessory.modbus.set-temperature")))
              ]),
              _c(
                "div",
                { staticClass: "mode-container" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "410px" },
                      attrs: {
                        label: _vm.$t("accessory.modbus.register-address")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "number" },
                        on: { change: _vm.onSetTargetPointChaged },
                        model: {
                          value:
                            _vm.accessoryData.details.device.actions
                              .setTargetPoint.address,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.accessoryData.details.device.actions
                                .setTargetPoint,
                              "address",
                              $$v
                            )
                          },
                          expression:
                            "accessoryData.details.device.actions.setTargetPoint.address"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "410px" },
                      attrs: { label: _vm.$t("accessory.modbus.factor") }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "number" },
                        on: { change: _vm.onSetTargetPointFactorChanged },
                        model: {
                          value:
                            _vm.accessoryData.details.device.actions
                              .setTargetPoint.factor,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.accessoryData.details.device.actions
                                .setTargetPoint,
                              "factor",
                              $$v
                            )
                          },
                          expression:
                            "accessoryData.details.device.actions.setTargetPoint.factor"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _vm._l(_vm.accessoryData.details.device.component, function(
            component
          ) {
            return _c(
              "el-tab-pane",
              { key: component.type, attrs: { name: component.type } },
              [
                _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                  _vm._v(_vm._s(_vm.titleTab(component.type)))
                ]),
                _c(
                  "span",
                  { staticStyle: { "margin-left": "15px" } },
                  [
                    _vm._v("\n                Title\n                "),
                    _c("el-input", {
                      staticStyle: {
                        width: "160px",
                        "margin-left": "10px",
                        "margin-top": "10px"
                      },
                      attrs: {
                        size: "small",
                        type: "text",
                        placeholder: "Title"
                      },
                      model: {
                        value: component.title,
                        callback: function($$v) {
                          _vm.$set(component, "title", $$v)
                        },
                        expression: "component.title"
                      }
                    })
                  ],
                  1
                ),
                _vm._l(
                  _vm.components.find(function(comp) {
                    return comp.type === component.type
                  }).modes,
                  function(mode, index) {
                    return _c(
                      "div",
                      { key: mode.type, staticClass: "item-container" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticStyle: { "margin-left": "5px" },
                            on: {
                              change: function($event) {
                                return _vm.setComponent(component)
                              }
                            },
                            model: {
                              value: mode.selected,
                              callback: function($$v) {
                                _vm.$set(mode, "selected", $$v)
                              },
                              expression: "mode.selected"
                            }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(mode.title) +
                                "\n                "
                            )
                          ]
                        ),
                        mode.selected
                          ? _c(
                              "div",
                              { staticClass: "mode-container" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "row",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Title\n                    "
                                    ),
                                    false
                                      ? _c(
                                          "el-popover",
                                          {
                                            attrs: { placement: "right" },
                                            model: {
                                              value:
                                                _vm.visibleIcons[mode.type],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.visibleIcons,
                                                  mode.type,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "visibleIcons[mode.type]"
                                            }
                                          },
                                          [
                                            _vm._l(_vm.modeIcons, function(
                                              row,
                                              index
                                            ) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "row"
                                                  }
                                                },
                                                _vm._l(row, function(
                                                  icon,
                                                  index
                                                ) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: icon.value,
                                                      staticStyle: {
                                                        display: "flex",
                                                        "flex-direction":
                                                          "column"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.onSelectIcon(
                                                                component.type,
                                                                mode.type,
                                                                icon
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "selectable-icon",
                                                            attrs: {
                                                              src:
                                                                "/assets/icons/" +
                                                                icon.value +
                                                                ".png"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                }),
                                                0
                                              )
                                            }),
                                            _c("img", {
                                              staticClass: "icon-contact",
                                              attrs: {
                                                slot: "reference",
                                                src:
                                                  "/assets/icons/" +
                                                  _vm.iconMode(
                                                    component.type,
                                                    mode.type
                                                  ) +
                                                  ".png"
                                              },
                                              slot: "reference"
                                            })
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _c("image-picker", {
                                      attrs: {
                                        images: _vm.allIcons[component.type],
                                        "selected-image": _vm.iconMode(
                                          component.type,
                                          mode.type
                                        ),
                                        placement: "right"
                                      },
                                      on: {
                                        selectImage: function($event) {
                                          return _vm.onSelectImage(
                                            component.type,
                                            mode.type,
                                            $event
                                          )
                                        }
                                      }
                                    }),
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "160px",
                                        "margin-left": "10px"
                                      },
                                      attrs: {
                                        size: "mini",
                                        type: "text",
                                        value: _vm.titleTabContent(
                                          component.type,
                                          mode.type
                                        ),
                                        placeholder: "Title"
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.onChangeModeName(
                                            $event,
                                            component.type,
                                            mode.type
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function($event) {
                                            return _vm.applyAll(component, mode)
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "fa fa-copy" })]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "410px" },
                                    attrs: { label: "Data Type" }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        on: {
                                          change: function($event) {
                                            return _vm.setComponent(component)
                                          }
                                        },
                                        model: {
                                          value:
                                            mode.actions["setMode"].addressType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              mode.actions["setMode"],
                                              "addressType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "mode.actions['setMode'].addressType"
                                        }
                                      },
                                      _vm._l(_vm.modeAddressTypes, function(
                                        addressType
                                      ) {
                                        return _c("el-option", {
                                          key: addressType.value,
                                          attrs: {
                                            label: addressType.title,
                                            value: addressType.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "410px" },
                                    attrs: { label: "Register Address" }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "number" },
                                      on: {
                                        change: function($event) {
                                          return _vm.setComponent(component)
                                        }
                                      },
                                      model: {
                                        value: mode.actions["setMode"].address,
                                        callback: function($$v) {
                                          _vm.$set(
                                            mode.actions["setMode"],
                                            "address",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "mode.actions['setMode'].address"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          mode.actions["setMode"].addressType ==
                                          1,
                                        expression:
                                          "mode.actions['setMode'].addressType == 1"
                                      }
                                    ],
                                    staticStyle: { width: "410px" },
                                    attrs: { label: "Active Value" }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "number" },
                                      on: {
                                        change: function($event) {
                                          return _vm.setComponent(component)
                                        }
                                      },
                                      model: {
                                        value:
                                          mode.actions["setMode"].statusValue,
                                        callback: function($$v) {
                                          _vm.$set(
                                            mode.actions["setMode"],
                                            "statusValue",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "mode.actions['setMode'].statusValue"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          mode.actions["setMode"].addressType ==
                                          0,
                                        expression:
                                          "mode.actions['setMode'].addressType == 0"
                                      }
                                    ],
                                    staticStyle: { width: "410px" },
                                    attrs: { label: "Active Value" }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "number",
                                        min: "0",
                                        max: "1"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.onAddressTypeChanged(
                                            component,
                                            mode,
                                            $event
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          mode.actions["setMode"].statusValue,
                                        callback: function($$v) {
                                          _vm.$set(
                                            mode.actions["setMode"],
                                            "statusValue",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "mode.actions['setMode'].statusValue"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  }
                )
              ],
              2
            )
          }),
          _vm.addableTabs.length > 0
            ? _c(
                "el-tab-pane",
                { attrs: { name: "addTab" } },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        slot: "label",
                        title: "Select Mode",
                        width: "300",
                        placement: "top"
                      },
                      slot: "label",
                      model: {
                        value: _vm.visibleAddTab,
                        callback: function($$v) {
                          _vm.visibleAddTab = $$v
                        },
                        expression: "visibleAddTab"
                      }
                    },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: { data: _vm.addableTabs, "show-header": false }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "title", width: "200" }
                          }),
                          _c("el-table-column", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { size: "mini" },
                                          on: {
                                            click: function($event) {
                                              return _vm.addTab(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("Select")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4098620044
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { attrs: { slot: "reference" }, slot: "reference" },
                        [
                          _c("i", {
                            staticClass: "fa fa-plus",
                            staticStyle: { margin: "10px" }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }