var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "details.io",
            label: _vm.$t("accessory.output-number"),
            rules: [
              {
                required: true,
                message: _vm.$t("accessory.form-rules.select-output")
              }
            ]
          }
        },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: _vm.$t("accessory.select-output") },
              model: {
                value: _vm.accessoryData.details.io,
                callback: function($$v) {
                  _vm.$set(_vm.accessoryData.details, "io", $$v)
                },
                expression: "accessoryData.details.io"
              }
            },
            _vm._l(_vm.outputs, function(index) {
              return _c(
                "el-option",
                {
                  key: index + "",
                  attrs: { label: "Q" + (index + 1), value: 8192 + index }
                },
                [
                  _c("span", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v(_vm._s("Q" + (index + 1)))
                  ]),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          "(" +
                            _vm.$t("accessory.output") +
                            " " +
                            (index + 1) +
                            ")"
                        )
                    )
                  ])
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticStyle: { width: "400px" },
          attrs: {
            prop: "details.switchControl",
            label: _vm.$t("accessory.key-control")
          }
        },
        [
          _c("el-switch", {
            model: {
              value: _vm.accessoryData.details.switchControl,
              callback: function($$v) {
                _vm.$set(_vm.accessoryData.details, "switchControl", $$v)
              },
              expression: "accessoryData.details.switchControl"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticStyle: { width: "400px" },
          attrs: { prop: "onValue", label: _vm.$t("accessory.normally") }
        },
        [
          _c("el-switch", {
            model: {
              value: _vm.onValue,
              callback: function($$v) {
                _vm.onValue = $$v
              },
              expression: "onValue"
            }
          })
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "400px" },
                  attrs: { prop: "pulse", label: _vm.$t("accessory.pulse") }
                },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.pulse,
                      callback: function($$v) {
                        _vm.pulse = $$v
                      },
                      expression: "pulse"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pulse,
                  expression: "pulse"
                }
              ],
              attrs: { span: 10 }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("accessory.duration"),
                    prop: "pulseDuration"
                  }
                },
                [
                  _c("el-input-number", {
                    attrs: { min: 10, step: 10 },
                    model: {
                      value: _vm.pulseDuration,
                      callback: function($$v) {
                        _vm.pulseDuration = $$v
                      },
                      expression: "pulseDuration"
                    }
                  }),
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v("ms.")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }