<template>
    <div :style="{marginLeft: editMode ? (210 + 'px') : (0 + 'px')}">
      <div class="form-item" v-for="(address, index) in knxAddresses" :key="index">
        <el-form-item prop="address" label-width="0" style="width: 100%">
  
          <span v-if="!isOptional(address.variable)">{{ title(address.variable) }}</span>
          <el-checkbox v-if="isOptional(address.variable)" v-model="selectedList[address.variable]" @change="onOptionalAddressChanged(address.variable, $event)">{{ title(address.variable) }}</el-checkbox>
          <div class="line"></div>
  
          <el-row :gutter="innerSpace" v-if="showAddressDetail(address.variable)">
            <el-col v-if="showWriteAddress(address.variable)" :span="3">{{ $t('accessory.write') }}</el-col>
            <el-col v-if="showWriteAddress(address.variable)" :span="6">
              <el-input type="text" v-model="address.writeAddress" placeholder=".../.../..." v-mask="groupAddressMask">
              </el-input>
            </el-col>
            <el-col v-if="showReadAddress(address.variable)" :span="3">{{ $t('accessory.read') }}</el-col>
            <el-col v-if="showReadAddress(address.variable)" :span="6">
              <el-input type="text" v-model="address.readAddress" placeholder=".../.../..." v-mask="groupAddressMask">
              </el-input>
            </el-col>
          </el-row>  
          <el-row v-if="address.variable == 'colorAbsoluteTemperature'" style="margin-top: 15px;" :gutter="innerSpace">
            <el-col :span="3">Min Value</el-col>
            <el-col :span="6">
              <el-input v-model="minColorAbsoluteTemperature" type="number" :min="1000" :max="maxColorAbsoluteTemperature - 1" placeholder="Min" @change="minValueCheck"></el-input>
            </el-col>
            <el-col :span="3">Max Value</el-col>
            <el-col :span="6">
              <el-input v-model="maxColorAbsoluteTemperature" type="number" :min="minColorAbsoluteTemperature + 1" :max="10000" placeholder="Max" @change="maxValueCheck"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </div>

    </div>
  
  </template>
  
  <script>
  import { min } from "moment";
import { knxMixin } from "./knxMixin";
  
  export default {
    name: "KnxRGBWDimmerInputs",
  
    mixins: [knxMixin],
  
    data: function() {
      return {
        selectedList: { brightnessValue: true, onOffState: true },
        minColorAbsoluteTemperature: 'minColorAbsoluteTemperature' in this.accessoryData ? this.accessoryData.minColorAbsoluteTemperature : 1000,
        maxColorAbsoluteTemperature: 'maxColorAbsoluteTemperature' in this.accessoryData ? this.accessoryData.maxColorAbsoluteTemperature : 10000,
        knxAddresses: [
          {
            variable: "onOffState",
            dpt: "1.001",
            writeAddress: "",
            readAddress: "",
          },
          {
            variable: "brightnessValue",
            dpt: "5.001",
            writeAddress: "",
            readAddress: "",
          },
          {
            variable: "colorAbsoluteTemperature",
            dpt: "7.600",
            writeAddress: "",
            readAddress: "",        
          }
        ],
      };
    },
  
    props: {
      accessoryData: {},
      editMode: {
        type: Boolean,
        default: false,
      },
    },


  
    watch: {
      knxAddresses: {
        handler: function(val) {
          val.forEach((address) => {
            var knxAddress = this.accessoryData.details.knxAddresses.find(
              (knxAddress) => knxAddress.variable === address.variable
            );
            if (knxAddress) {
              this.accessoryData.details.knxAddresses[
                this.accessoryData.details.knxAddresses.indexOf(knxAddress)
              ] = address;
            }
          });
        },
        deep: true,
      },
      "selectedList.onOffState": function(newVal) {
        this.accessoryData.switchAvailable = newVal;
      },
      "selectedList.brightnessValue": function(newVal) {
        this.accessoryData.brightnessAvailable = newVal;
      },
    },
  
    methods: {
      isOptional(variable) {
        if (
          variable === "brightnessValue" ||
          variable === "onOffState"
        )
          return true;
  
        return false;
      },
      showAddressDetail(variable) {
        if (this.isOptional(variable)) {
          return this.selectedList[variable];
        }
  
        return true;
      },
      onOptionalAddressChanged(variable, value) {
        var address = this.accessoryData.details.knxAddresses.find((address) => {
          return address.variable === variable;
        });
  
        if (this.isOptional(variable)) {
          var cachedKnxAddress = {
            ...this.knxAddresses.find((address) => address.variable === variable),
          };
  
          if (address) cachedKnxAddress = address;
  
          if (value) {
            this.accessoryData.details.knxAddresses.push(cachedKnxAddress);
          } else {
            this.accessoryData.details.knxAddresses.splice(
              this.accessoryData.details.knxAddresses.indexOf(address),
              1
            );
          }
        }
      },
      minValueCheck(currentVal){
        this.accessoryData.minColorAbsoluteTemperature = parseInt(currentVal < this.maxColorAbsoluteTemperature && currentVal >= 1000 ? currentVal : 1000, 10);
      },
      maxValueCheck(currentVal){
        this.accessoryData.maxColorAbsoluteTemperature = parseInt(currentVal > this.minColorAbsoluteTemperature && currentVal <= 10000 ? currentVal : 10000, 10);
      },
      validate() {
        const failAddresses = this.accessoryData.details.knxAddresses.filter(
          (address) => {
            return (
              (this.showReadAddress(address.variable) &&
                !this.validateKnxGroupAddress(address.readAddress)) ||
              (this.showWriteAddress(address.variable) &&
                !this.validateKnxGroupAddress(address.writeAddress))
            );
          }
        );
  
        if (failAddresses.length > 0) {
          this.$message({
            type: "error",
            message: this.$t("accessory.form-rules.knx-address-invalid"),
          });
          return false;
        } else {
          return true;
        }
      },
    },
  
    created() {
      if (!this.accessoryData.hasOwnProperty("switchAvailable"))
        this.accessoryData.switchAvailable = this.selectedList.onOffState;
      if (!this.accessoryData.hasOwnProperty("brightnessAvailable"))
        this.accessoryData.brightnessAvailable = this.selectedList.brightnessValue;
      if (!this.accessoryData.hasOwnProperty('minColorAbsoluteTemperature'))
        this.accessoryData.minColorAbsoluteTemperature = this.minColorAbsoluteTemperature
      if (!this.accessoryData.hasOwnProperty('maxColorAbsoluteTemperature'))
        this.accessoryData.maxColorAbsoluteTemperature = this.maxColorAbsoluteTemperature
  
      if (!this.accessoryData.details.knxAddresses) {
        this.accessoryData.details = {
          knxAddresses: JSON.parse(JSON.stringify(this.knxAddresses)),
        };
      } else {
        this.accessoryData.details.knxAddresses.forEach((addr) => {
          var address = this.knxAddresses.find(
            (address) => address.variable == addr.variable
          );
          if (address) {
            address.writeAddress = addr.writeAddress;
            address.readAddress = addr.readAddress;
          }
        });
  
        var onOffState = this.accessoryData.details.knxAddresses.find(
          (address) => address.variable === "onOffState"
        );
        var brightnessValue = this.accessoryData.details.knxAddresses.find(
          (address) => address.variable === "brightnessValue"
        );
  
        this.selectedList.onOffState = onOffState != undefined;
        this.selectedList.brightnessValue = brightnessValue != undefined;
      }
    },
  };
  </script>
  
  <style scoped>
  .line {
    height: 1px;
    margin: 0px 0px 20px 0px;
    background-color: #eaeaea;
  }
  .form-item {
    margin: 10px;
    padding: 10px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    color: #606266;
  }
  .form-item span {
    font-weight: bold;
  }
  </style>
  