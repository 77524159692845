<template>

  <div :style="{marginLeft: editMode ? (210 + 'px') : (0 + 'px')}">
    <div>
      <div class="form-item" v-for="(address, index) in knxAddresses" :key="index">
        <el-form-item prop="address" label-width="0" style="width: 100%">

          <span v-if="!isOptional(address.variable)">{{ title(address.variable) }}</span>
          <el-checkbox v-if="isOptional(address.variable)" v-model="selectedList[address.variable]" @change="onOptionalAddressChanged(address.variable, $event)">{{ title(address.variable) }}</el-checkbox>
          <div class="line"></div>

          <el-row :gutter="innerSpace" v-if="showAddressDetail(address.variable)">
            <el-col v-if="showWriteAddress(address.variable)" :span="3">{{ $t('accessory.write') }}</el-col>
            <el-col v-if="showWriteAddress(address.variable)" :span="6">
              <el-input type="text" v-model="address.writeAddress" placeholder=".../.../..." v-mask="groupAddressMask">
              </el-input>
            </el-col>
            <el-col v-if="showReadAddress(address.variable)" :span="3">{{ $t('accessory.read') }}</el-col>
            <el-col v-if="showReadAddress(address.variable)" :span="6">
              <el-input type="text" v-model="address.readAddress" placeholder=".../.../..." v-mask="groupAddressMask">
              </el-input>
            </el-col>
          </el-row>

        </el-form-item>

        <el-form-item v-if="address.variable == 'deviceLevel'" :label="$t('accessory.level-type')" label-width="112px">
          <el-select v-model="accessoryData.levelType" style="width: 220px">
            <el-option v-for="levelType in levelTypes" :label="levelType.title" :value="levelType.value" :key="levelType.value + ''">
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item v-if="address.variable == 'actionClose' && showAddressDetail(address.variable)" :label="$t('accessory.active-value')" label-width="112px">
          <span>
            <el-input class="input-item" size="mini" type="text" v-model="accessoryData.activeValue" v-mask="maskDecimal" placeholder="Active Value" @blur="onBlurActiveValue" @change="onBlurActiveValue"></el-input>
          </span>
        </el-form-item> -->

      </div>
    </div>
  </div>

</template>

<script>
  import {knxMixin} from "./knxMixin";
  import KnxGroupAddress from "./KnxGroupAddress"

  export default {
    name: "KnxShutterInputs",

    mixins: [knxMixin],

    data: function(){
      return {
          levelTypes: [{title: "Open: 0% - Closed: 100%", value: 0}, {title: "Open: 100% - Closed: 0%", value: 1}],
          selectedList: { deviceLevel: true, slatLevel: false,  },

          knxAddresses: [
            {
              variable: "actionMove",
              writeAddress: "",
              readAddress: ""
            },
            {
              variable: "actionStop",
              writeAddress: "",
              readAddress: ""
            },
            // {
            //   variable: "actionClose",
            //   writeAddress: "",
            //   readAddress: ""
            // },
            {
              variable: "deviceLevel",
              writeAddress: "",
              readAddress: ""
            },
            {
              variable: "slatLevel",
              writeAddress: "",
              readAddress: ""
            }
          ],
        variables: {
          slatLevel: {
            title: "Slat Level (1 Byte)", showWriteAddress: true, showReadAddress: true
          },
          deviceLevel: {
            title: "Level Percentage (1 Byte)", showWriteAddress: true, showReadAddress: true
          },
          actionMove: {
            title: this.$t('accessory.knx.action-move'), showWriteAddress: true, showReadAddress: false
          },
          actionStop: {
            title: this.$t('accessory.knx.action-stop'), showWriteAddress: true, showReadAddress: false
          },
          // actionClose: {
          //   title: this.$t('accessory.knx.action-close'), showWriteAddress: false, showReadAddress: true
          // }
        },
      }
    },
    
    props: {
      accessoryData: {},
      editMode: {
        type: Boolean,
        default: false
      }
    },

    watch:{
        knxAddresses: {
            handler: function(val, valOld){
                val.forEach(address => {
                    var knxAddress = this.accessoryData.details.knxAddresses.find(knxAddress => knxAddress.variable === address.variable)
                    if(knxAddress){
                      this.accessoryData.details.knxAddresses[this.accessoryData.details.knxAddresses.indexOf(knxAddress)] = address
                    }
                });
              console.log(this.accessoryData.details.knxAddresses)
            },
            deep:true
        }
    },

    methods:{
        isOptional(variable){
            // if(variable === "deviceLevel" || variable === "slatLevel" || variable === "actionClose")
            if(variable === "deviceLevel" || variable === "slatLevel")
              return true;

          return false;
        },
        showAddressDetail(variable){
            if(this.isOptional(variable)){
                return this.selectedList[variable]
            }

            return true;
        },
        onOptionalAddressChanged(variable, value){

          console.log("Selection Changes", variable, value)

          var address = this.accessoryData.details.knxAddresses.find(address => address.variable === variable)

          if(variable === "deviceLevel" || variable === "slatLevel"){

            var cachedKnxAddress = {...this.knxAddresses.find(address => address.variable === variable)}

            if(address)
              cachedKnxAddress = address

            if(value){
              this.accessoryData.details.knxAddresses.push(cachedKnxAddress)
            } else{
              this.accessoryData.details.knxAddresses.splice(this.accessoryData.details.knxAddresses.indexOf(address), 1)
            }

            console.log("onOptionalAddressChanged", this.accessoryData.details.knxAddresses)
          }
        },

      validate(){
        const failAddresses = this.accessoryData.details.knxAddresses.filter(address => {
          return (this.showReadAddress(address.variable) && !this.validateKnxGroupAddress(address.readAddress))
            || (this.showWriteAddress(address.variable) && !this.validateKnxGroupAddress(address.writeAddress))
        })

        if (failAddresses.length > 0) {
          this.$message({ type: "error", message: this.$t('accessory.form-rules.knx-address-invalid')})
          return false
        } else {
          return true
        }
      }
    },

    created(){
      console.log("1", this.accessoryData.details.knxAddresses)
      if (!this.accessoryData.levelType) {
        this.accessoryData.levelType = 0
      }

      if (!this.accessoryData.details.knxAddresses) {
        this.accessoryData.details = {
          knxAddresses: [
            {
              variable: "actionMove",
              writeAddress: "",
              readAddress: ""
            },
            {
              variable: "actionStop",
              writeAddress: "",
              readAddress: ""
            },
            // {
            //   variable: "actionClose",
            //   writeAddress: "",
            //   readAddress: ""
            // },
            {
              variable: "deviceLevel",
              writeAddress: "",
              readAddress: ""
            },
            /*{
              variable: "slatLevel",
              writeAddress: "",
              readAddress: ""
            }*/
          ]
        }
      } else{
        //this.knxAddresses = [...this.accessoryData.details.knxAddresses]

        this.accessoryData.details.knxAddresses.forEach(addr => {
          var address = this.knxAddresses.find(address => address.variable == addr.variable)
          if (address) {
            address.writeAddress = addr.writeAddress
            address.readAddress = addr.readAddress
          }
        })

        console.log("2", this.knxAddresses)

        var deviceLevel = this.accessoryData.details.knxAddresses.find(address => address.variable === "deviceLevel");
        /*if(!deviceLevel){
            this.knxAddresses.push({
              variable: "deviceLevel",
              writeAddress: "",
              readAddress: ""
            })
        }*/

        var slatLevel = this.accessoryData.details.knxAddresses.find(address => address.variable === "slatLevel");

        /*
        if(!slatLevel){
          this.knxAddresses.push({
            variable: "slatLevel",
            writeAddress: "",
            readAddress: ""
          })
        }*/

        // var actionClose = this.accessoryData.details.knxAddresses.find(address => address.variable === "actionClose");

        this.selectedList.deviceLevel = deviceLevel != undefined
        this.selectedList.slatLevel = slatLevel != undefined
        // this.selectedList.actionClose = actionClose != undefined
      }

    }
  }
</script>

<style scoped>
  .line {
    height: 1px;
    margin: 0px 0px 20px 0px;
    background-color: #eaeaea;
  }
  .form-item {
    margin: 10px;
    padding: 10px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    color: #606266;
  }
  .form-item span {
    font-weight: bold;
  }
  .input-item {
    width: 160px;
    margin-left: 10px;
    margin-right: 20px
  }
</style>