var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { marginLeft: _vm.editMode ? 210 + "px" : 0 + "px" } },
    [
      _c(
        "div",
        _vm._l(_vm.knxAddresses, function(address, index) {
          return _c(
            "div",
            { key: index, staticClass: "form-item" },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { prop: "address", "label-width": "0" }
                },
                [
                  !_vm.isOptional(address.variable)
                    ? _c("span", [_vm._v(_vm._s(_vm.title(address.variable)))])
                    : _vm._e(),
                  _vm.isOptional(address.variable)
                    ? _c(
                        "el-checkbox",
                        {
                          on: {
                            change: function($event) {
                              return _vm.onOptionalAddressChanged(
                                address.variable,
                                $event
                              )
                            }
                          },
                          model: {
                            value: _vm.selectedList[address.variable],
                            callback: function($$v) {
                              _vm.$set(_vm.selectedList, address.variable, $$v)
                            },
                            expression: "selectedList[address.variable]"
                          }
                        },
                        [_vm._v(_vm._s(_vm.title(address.variable)))]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "line" }),
                  _vm.showAddressDetail(address.variable)
                    ? _c(
                        "el-row",
                        { attrs: { gutter: _vm.innerSpace } },
                        [
                          _vm.showWriteAddress(address.variable)
                            ? _c("el-col", { attrs: { span: 3 } }, [
                                _vm._v(_vm._s(_vm.$t("accessory.write")))
                              ])
                            : _vm._e(),
                          _vm.showWriteAddress(address.variable)
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: _vm.groupAddressMask,
                                        expression: "groupAddressMask"
                                      }
                                    ],
                                    attrs: {
                                      type: "text",
                                      placeholder: ".../.../..."
                                    },
                                    model: {
                                      value: address.writeAddress,
                                      callback: function($$v) {
                                        _vm.$set(address, "writeAddress", $$v)
                                      },
                                      expression: "address.writeAddress"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showReadAddress(address.variable)
                            ? _c("el-col", { attrs: { span: 3 } }, [
                                _vm._v(_vm._s(_vm.$t("accessory.read")))
                              ])
                            : _vm._e(),
                          _vm.showReadAddress(address.variable)
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: _vm.groupAddressMask,
                                        expression: "groupAddressMask"
                                      }
                                    ],
                                    attrs: {
                                      type: "text",
                                      placeholder: ".../.../..."
                                    },
                                    model: {
                                      value: address.readAddress,
                                      callback: function($$v) {
                                        _vm.$set(address, "readAddress", $$v)
                                      },
                                      expression: "address.readAddress"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              address.variable == "deviceLevel"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("accessory.level-type"),
                        "label-width": "112px"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "220px" },
                          model: {
                            value: _vm.accessoryData.levelType,
                            callback: function($$v) {
                              _vm.$set(_vm.accessoryData, "levelType", $$v)
                            },
                            expression: "accessoryData.levelType"
                          }
                        },
                        _vm._l(_vm.levelTypes, function(levelType) {
                          return _c("el-option", {
                            key: levelType.value + "",
                            attrs: {
                              label: levelType.title,
                              value: levelType.value
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }