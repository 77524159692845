var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "details.id",
            label: _vm.$t("accessory.input-number"),
            rules: [
              {
                required: true,
                message: _vm.$t("accessory.form-rules.select-input")
              }
            ]
          }
        },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: _vm.$t("accessory.select-input") },
              model: {
                value: _vm.accessoryData.details.id,
                callback: function($$v) {
                  _vm.$set(_vm.accessoryData.details, "id", $$v)
                },
                expression: "accessoryData.details.id"
              }
            },
            _vm._l(_vm.availableInputs, function(input, index) {
              return _c(
                "el-option",
                {
                  key: input.value,
                  attrs: { label: input.title, value: input.value }
                },
                [_c("span", [_vm._v(" " + _vm._s(input.title))])]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticStyle: { width: "400px" },
          attrs: { prop: "trippedValue", label: _vm.$t("accessory.normally") }
        },
        [
          _c("el-switch", {
            attrs: { "active-value": true, "inactive-value": false },
            model: {
              value: _vm.trippedValue,
              callback: function($$v) {
                _vm.trippedValue = $$v
              },
              expression: "trippedValue"
            }
          })
        ],
        1
      ),
      !_vm.isSuddenSensor()
        ? _c(
            "el-form-item",
            {
              staticStyle: { width: "400px" },
              attrs: {
                prop: "entryDelay",
                label: _vm.$t("accessory.entry-delay"),
                rules: [
                  {
                    required: true,
                    message: _vm.$t("accessory.form-rules.enter-entry-delay")
                  }
                ]
              }
            },
            [
              _c("el-input", {
                attrs: {
                  type: "number",
                  placeholder: _vm.$t("accessory.enter-entry-delay")
                },
                model: {
                  value: _vm.accessoryData.entryDelay,
                  callback: function($$v) {
                    _vm.$set(_vm.accessoryData, "entryDelay", $$v)
                  },
                  expression: "accessoryData.entryDelay"
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.isSuddenSensor()
        ? _c(
            "el-form-item",
            {
              staticStyle: { width: "400px" },
              attrs: {
                prop: "armStateInAwayMode",
                label: _vm.$t("accessory.away")
              }
            },
            [
              _c("el-switch", {
                on: { change: _vm.changedSwitch },
                model: {
                  value: _vm.armStateInAwayMode,
                  callback: function($$v) {
                    _vm.armStateInAwayMode = $$v
                  },
                  expression: "armStateInAwayMode"
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.isSuddenSensor()
        ? _c(
            "el-form-item",
            {
              staticStyle: { width: "400px" },
              attrs: {
                prop: "armStateInStayMode",
                label: _vm.$t("accessory.stay")
              }
            },
            [
              _c("el-switch", {
                model: {
                  value: _vm.armStateInStayMode,
                  callback: function($$v) {
                    _vm.armStateInStayMode = $$v
                  },
                  expression: "armStateInStayMode"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }