<template>
  <div :style="{marginLeft: editMode ? (210 + 'px') : (0 + 'px')}">

    <div class="form-item" v-for="(address, index) in accessoryData.details.knxAddresses" :key="index">

      <el-form-item prop="address" label-width="0" style="width: 100%">

        <span>{{ title(address.variable) }}</span>
        <div class="line"></div>

        <el-row :gutter="innerSpace">

          <el-col :span="2">DPT</el-col>
          <el-col :span="6">
            <el-select v-model="address.dpt" placeholder="DPT">
              <el-option v-for="dpt in dataPoints" :label="dpt.title" :value="dpt.value" :key="dpt.value">
              </el-option>
            </el-select>
          </el-col>

          <el-col v-if="showWriteAddress(address.variable)" :span="2">Address</el-col>
          <el-col v-if="showWriteAddress(address.variable)" :span="4">
            <el-input type="text" v-model="address.writeAddress" placeholder=".../.../..." v-mask="groupAddressMask">
            </el-input>
          </el-col>

          <el-col :span="2">Value</el-col>
          <el-col :span="4">
            <el-input type="text" v-model="address.value" v-mask="maskDecimal" placeholder="Value"></el-input>
          </el-col>

        </el-row>

      </el-form-item>

    </div>

  </div>
</template>


<script>
  import {knxMixin} from "./knxMixin";

  export default {
    name: "KnxBinaryInputs",

    mixins: [knxMixin],

    props: {
      accessoryData: {},
      editMode: {
        type: Boolean,
        default: false
      }
    },

    data: function(){
      return {
        data: {},
        temp: "",
      }
    },

    methods: {
      validate(){
        var message = ""
        this.accessoryData.details.knxAddresses.forEach(address => {
          var msg = []
          if ( (this.showReadAddress(address.variable) && !this.validateKnxGroupAddress(address.readAddress))
            || (this.showWriteAddress(address.variable) && !this.validateKnxGroupAddress(address.writeAddress)) ) {
            msg.push(this.$t('accessory.form-rules.knx-address-invalid'))
          }
          if (!address.dpt) {
            msg.push(this.$t('accessory.form-rules.dpt-empty'))
          }
          if (!this.validateKnxValue(address.dpt, address.value)) {
            msg.push(this.$t('accessory.form-rules.knx-value-invalid'))
          }

          if (msg.length > 0) {
            const msgStr = msg.join(", ")
            message += "[" + address.variable + "] " + msgStr + "<br>"
          }
        })

        if (message) {
          this.$message({ type: "error", message: message, dangerouslyUseHTMLString: true})
          return false
        } else {
          return true
        }
      }
    },

    created(){
      console.log("binary accessory", this.accessoryData)

      if (!this.accessoryData.details.knxAddresses) {
        this.accessoryData.details = {
          knxAddresses: [
            {
              variable: "actionOn",
              writeAddress: "",
              readAddress: ""
            },
            {
              variable: "actionOff",
              writeAddress: "",
              readAddress: ""
            }
          ]
        }
      }
    }
  }
</script>

<style scoped>
  .line {
    height: 1px;
    margin: 0px 0px 20px 0px;
    background-color: #eaeaea;
  }
  .form-item {
    margin: 10px;
    padding: 10px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    color: #606266;
  }
  .form-item span {
    font-weight: bold;
  }
</style>