var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { marginLeft: _vm.editMode ? 210 + "px" : 0 + "px" } },
    [
      _vm._l(_vm.accessoryData.details.knxAddresses, function(address, index) {
        return _c(
          "div",
          { key: index, staticClass: "form-item" },
          [
            _c("KnxGroupAddress", {
              attrs: {
                address: address,
                "enable-write-address": false,
                "enable-write-value": false,
                "enable-active-value": false
              },
              on: { changeKnxAddress: _vm.onChangeKnxField }
            })
          ],
          1
        )
      }),
      !_vm.showSelectText
        ? _c(
            "div",
            { staticStyle: { margin: "20px 10px 10px 10px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("accessory.unit") } },
                [
                  _c("el-autocomplete", {
                    attrs: {
                      "fetch-suggestions": _vm.queryUnit,
                      placeholder: _vm.$t("accessory.enter-unit")
                    },
                    on: { select: _vm.handleSelect },
                    model: {
                      value: _vm.unit,
                      callback: function($$v) {
                        _vm.unit = $$v
                      },
                      expression: "unit"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showSelectText
        ? _c(
            "div",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "details.onValueText",
                    label: _vm.$t("accessory.on-value-text"),
                    rules: [{ required: true }]
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("accessory.enter-status-text")
                    },
                    model: {
                      value: _vm.accessoryData.details.onValueText,
                      callback: function($$v) {
                        _vm.$set(_vm.accessoryData.details, "onValueText", $$v)
                      },
                      expression: "accessoryData.details.onValueText"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "details.onValueText",
                    label: _vm.$t("accessory.off-value-text"),
                    rules: [{ required: true }]
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("accessory.enter-status-text")
                    },
                    model: {
                      value: _vm.accessoryData.details.offValueText,
                      callback: function($$v) {
                        _vm.$set(_vm.accessoryData.details, "offValueText", $$v)
                      },
                      expression: "accessoryData.details.offValueText"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }