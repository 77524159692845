<template>
  <div>

      <el-form-item :label="$t('accessory.indoor-id')" prop="details.indoorId" style="width: 400px">
          <el-input type="text" v-model="accessoryData.details.indoorId" auto-complete="on" :placeholder="$t('accessory.type-indoor-id')">
          </el-input>
        </el-form-item>

  </div>
</template>

<script>
export default {
  name: "CoolmasterThermostatInputs",
  data: function() {
    return {};
  },

  props: {
    accessoryData: {},
  },
};
</script>

<style scoped>
</style>
