var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { marginLeft: _vm.editMode ? 210 + "px" : 0 + "px" } },
    _vm._l(_vm.knxAddresses, function(address, index) {
      return _c(
        "div",
        { key: index, staticClass: "form-item" },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { width: "100%" },
              attrs: { prop: "address", "label-width": "0" }
            },
            [
              _c(
                "div",
                { staticClass: "item-container" },
                [
                  address.optional
                    ? _c(
                        "el-checkbox",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { value: false },
                          on: {
                            change: function($event) {
                              return _vm.onOptionalAddressChanged(
                                address,
                                $event
                              )
                            }
                          },
                          model: {
                            value: _vm.selectedList[address.optionalModel],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.selectedList,
                                address.optionalModel,
                                $$v
                              )
                            },
                            expression: "selectedList[address.optionalModel]"
                          }
                        },
                        [_vm._v(_vm._s(address.title))]
                      )
                    : _c("span", [_vm._v(_vm._s(address.title))]),
                  (address.optional &&
                    _vm.selectedList[address.optionalModel]) ||
                  !address.optional
                    ? _c(
                        "div",
                        { staticClass: "mode-container" },
                        [
                          _c("KnxGroupAddress", {
                            attrs: {
                              address: address,
                              enableDpt: false,
                              enableWriteAddress: address.showWriteAddress,
                              enableReadAddress: address.showReadAddress,
                              enableActiveValue: address.showActiveValue,
                              enableWriteValue: address.showWriteValue
                            },
                            on: { changeKnxAddress: _vm.onChangeKnxField }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }