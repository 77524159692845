<template>
  <div :style="{marginLeft: editMode ? (210 + 'px') : (0 + 'px')}">

    <el-radio-group class="radio-group" v-model="accessoryData.details.thermostatType">
      <el-radio-button label="all">{{ $t('accessory.knx.heating-cooling') }}</el-radio-button>
      <el-radio-button label="heat">{{ $t('accessory.knx.heating-only') }}</el-radio-button>
      <el-radio-button label="cool">{{ $t('accessory.knx.cooling-only') }}</el-radio-button>
    </el-radio-group>


    <div class="form-item" v-for="(address, index) in accessoryData.details.knxAddresses" :key="index">
      <el-form-item prop="address" label-width="0" style="width: 100%">

        <span>{{ title(address.variable) + ' '}}</span>
        <div class="line"></div>

        <el-row :gutter="innerSpace">
          <el-col v-if="showWriteAddress(address.variable)" :span="3">{{ $t('accessory.write') }}</el-col>
          <el-col v-if="showWriteAddress(address.variable)" :span="6">
            <el-input type="text" v-model="address.writeAddress" placeholder=".../.../..." v-mask="groupAddressMask">
            </el-input>
          </el-col>
          <el-col v-if="showReadAddress(address.variable)" :span="3">{{ $t('accessory.read') }}</el-col>
          <el-col v-if="showReadAddress(address.variable)" :span="6">
            <el-input type="text" v-model="address.readAddress" placeholder=".../.../..." v-mask="groupAddressMask">
            </el-input>
          </el-col>
        </el-row>

      </el-form-item>
    </div>


  </div>

</template>

<script>
  import {knxMixin} from "./knxMixin";

  export default {
    name: "KnxThermostatInputs",

    mixins: [knxMixin],

    data: function(){
      return {
        onOff: "bit",
        onOffTypes: [
          {value: "bit", label: "(1 Bit)"},
          {value: "byte", label: "(1 Byte)"}
        ]
      }
    },

    props: {
      accessoryData: {},
      editMode: {
        type: Boolean,
        default: false
      },
    },


    methods: {
      changeOnOffType(value){
        if (value == "bit") {
          let address = this.accessoryData.details.knxAddresses.find(address => address.variable == 'hvacMode')
          if (address) {
            address.variable = "offStatus"
          }
        } else {
          if (value == "byte") {
            let address = this.accessoryData.details.knxAddresses.find(address => address.variable == 'offStatus')
            if (address) {
              address.variable = "hvacMode"
            }
          }
        }
      },


      validate(){
        const failAddresses = this.accessoryData.details.knxAddresses.filter(address => {
          return (this.showReadAddress(address.variable) && !this.validateKnxGroupAddress(address.readAddress))
            || (this.showWriteAddress(address.variable) && !this.validateKnxGroupAddress(address.writeAddress))
        })

        if (failAddresses.length > 0) {
          this.$message({ type: "error", message: this.$t('accessory.form-rules.knx-address-invalid')})
          return false
        } else {
          return true
        }
      }
    },

    created(){

      if (!this.accessoryData.thermoMode)
        this.accessoryData.thermoMode = "thermoModeOff"

      if (!this.accessoryData.details.knxAddresses) {
        this.accessoryData.details = {
          thermostatType: "all",
          knxAddresses: [
            {
              variable: "setPoint",
              writeAddress: "",
              readAddress: ""
            },
            {
              variable: "switchOver",
              writeAddress: "",
              readAddress: ""
            },
            {
              variable: "hvacMode",
              writeAddress: "",
              readAddress: ""
            },
            {
              variable: "offStatus",
              writeAddress: "",
              readAddress: ""
            },
            {
              variable: "temperature",
              writeAddress: "",
              readAddress: ""
            }
          ]
        }
      }
    }
  }
</script>

<style scoped>
  .line {
    height: 1px;
    margin: 0px 0px 20px 0px;
    background-color: #eaeaea;
  }
  .form-item {
    margin: 10px;
    padding: 10px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    color: #606266;
  }
  .radio-group {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
  }
  .form-item span {
    font-weight: bold;
  }

</style>