var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        {
          staticStyle: { width: "410px" },
          attrs: {
            prop: "details.device.unitId",
            label: _vm.$t("accessory.modbus.device-address")
          }
        },
        [
          _c("el-input", {
            attrs: { min: "0", type: "number" },
            model: {
              value: _vm.accessoryData.details.device.unitId,
              callback: function($$v) {
                _vm.$set(_vm.accessoryData.details.device, "unitId", $$v)
              },
              expression: "accessoryData.details.device.unitId"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "details.device.actions.setOn.addressType",
            label: _vm.$t("accessory.modbus.address-type")
          }
        },
        [
          _c(
            "el-select",
            {
              on: { change: _vm.onSelectSetOnAddressType },
              model: {
                value: _vm.addressType,
                callback: function($$v) {
                  _vm.addressType = $$v
                },
                expression: "addressType"
              }
            },
            _vm._l(_vm.switchAddressTypeNames, function(addressType) {
              return _c("el-option", {
                key: addressType.value,
                attrs: { label: addressType.title, value: addressType.value }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticStyle: { width: "410px" },
          attrs: {
            prop: "details.device.actions.setOn.address",
            label: _vm.$t("accessory.modbus.register-address")
          }
        },
        [
          _c("el-input", {
            attrs: { min: "0", type: "number" },
            on: { change: _vm.onRegisterAddressChanged },
            model: {
              value: _vm.accessoryData.details.device.actions.setOn.address,
              callback: function($$v) {
                _vm.$set(
                  _vm.accessoryData.details.device.actions.setOn,
                  "address",
                  $$v
                )
              },
              expression: "accessoryData.details.device.actions.setOn.address"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.addressType == _vm.ADDRESS_TYPE_HOLDING_REGISTER,
              expression: "addressType == ADDRESS_TYPE_HOLDING_REGISTER"
            }
          ],
          staticStyle: { width: "410px" },
          attrs: {
            prop: "details.device.actions.setOn.onValue",
            label: _vm.$t("accessory.modbus.on-value")
          }
        },
        [
          _c("el-input", {
            attrs: { min: "0", type: "number" },
            on: { change: _vm.onOnValueChanged },
            model: {
              value: _vm.accessoryData.details.device.actions.setOn.onValue,
              callback: function($$v) {
                _vm.$set(
                  _vm.accessoryData.details.device.actions.setOn,
                  "onValue",
                  $$v
                )
              },
              expression: "accessoryData.details.device.actions.setOn.onValue"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.addressType == _vm.ADDRESS_TYPE_HOLDING_REGISTER,
              expression: "addressType == ADDRESS_TYPE_HOLDING_REGISTER"
            }
          ],
          staticStyle: { width: "410px" },
          attrs: {
            prop: "details.device.actions.setOn.offValue",
            label: _vm.$t("accessory.modbus.off-value")
          }
        },
        [
          _c("el-input", {
            attrs: { min: "0", type: "number" },
            on: { change: _vm.onOffValueChanged },
            model: {
              value: _vm.accessoryData.details.device.actions.setOn.offValue,
              callback: function($$v) {
                _vm.$set(
                  _vm.accessoryData.details.device.actions.setOn,
                  "offValue",
                  $$v
                )
              },
              expression: "accessoryData.details.device.actions.setOn.offValue"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.addressType == _vm.ADDRESS_TYPE_COIL,
              expression: "addressType == ADDRESS_TYPE_COIL"
            }
          ],
          staticStyle: { width: "410px" },
          attrs: {
            prop: "coilOnValue",
            label: _vm.$t("accessory.modbus.on-value")
          }
        },
        [
          _c("el-input", {
            attrs: { min: "0", type: "number" },
            on: { change: _vm.onOnValueChanged },
            model: {
              value: _vm.coilOnValue,
              callback: function($$v) {
                _vm.coilOnValue = $$v
              },
              expression: "coilOnValue"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }