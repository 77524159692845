<template>
  <el-popover ref="picker" :placement="placement" :width="width" :trigger="trigger" v-model="visible">

    <el-row v-for="(row, index) in imageList" :key="index">
      <el-col :span="24/columns" v-for="(image, index) in row" :key="index">
        <span @click="onSelectImage(image)" class="icon-item-layout">
          <img @error="onImageError" :src="'/assets/icons/' + image + '.png'" class="icon-item">
        </span>
      </el-col>
    </el-row>

    <span slot="reference">
      <img @error="onImageError" :src="'/assets/icons/' + currentImage + '.png'" class="icon">
    </span>
  </el-popover>
</template>

<script>
  export default {
    name: "ImagePicker",

    props: {
      title: {
        type: String,
        default: null
      },

      placement: {
        type: String,
        default: "top"
      },

      trigger: {
        type: String,
        default: "click"
      },

      columns: {
        type: Number,
        default: 4
      },

      images: {
        type: Array,
        required: true
      },

      selectedImage: {
        type: String,
        default: ""
      }
    },

    data: function(){
      return {
        imageList: new Array(),
        currentImage: "",
        width: 300,
        visible: false
      }
    },

    watch: {
      selectedImage: function (newVal) {
        console.log("change image", newVal)
        this.currentImage = this.selectedImage
      }
    },

    methods: {
      onImageError: function(e){
        console.log("on image error");
        e.target.src = "/assets/person.png";
      },

      onSelectImage: function (image) {
        console.log("selected", image)
        this.currentImage = image
        this.visible = false
        this.$emit("selectImage", image)
      }
    },



    created(){

      this.currentImage = this.selectedImage

      const rowCount = Math.ceil(this.images.length / this.columns)

      console.log("images", this.images)

      if (rowCount <= 0)
        return

      if (this.columns <= 0)
        return

      this.width = this.columns * 54

      var col = 0
      var rows = new Array()

      this.images.forEach((image, index) => {


        rows.push(image)
        if (++col >= this.columns) {
          col = 0
          this.imageList.push(rows)
          rows = new Array()
        }
      })

      if (rows.length > 0)
        this.imageList.push(rows)

      console.log("imageList", this.imageList)
    }
  }
</script>

<style scoped>

  .icon {
    filter: brightness(0.45);
    width: 30px;
    height: 30px;
    margin: 5px;
  }

  .icon:hover {
    cursor: pointer;
  }

  .icon-item {
    filter: brightness(0.45);
    width: 30px;
    height: 30px;
  }

  .icon-item-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccccc;
    padding: 10px;
    margin: 2px;
  }
  .icon-item-layout:hover {
    border-color: #545c64;
    cursor: pointer;
  }

</style>