import {
  ACCESSORY_CATEGORY_CONTACTOR,
  ACCESSORY_CATEGORY_ELECTRICAL_DEVICE,
  ACCESSORY_CATEGORY_ELEVATOR,
  ACCESSORY_CATEGORY_GAS_VALVE,
  ACCESSORY_CATEGORY_SENSOR_DOOR,
  ACCESSORY_CATEGORY_SENSOR_GAS,
  ACCESSORY_CATEGORY_SENSOR_LEAK,
  ACCESSORY_CATEGORY_SENSOR_MOTION,
  ACCESSORY_CATEGORY_SENSOR_SMOKE,
  ACCESSORY_CATEGORY_SENSOR_WINDOW,
  ACCESSORY_CATEGORY_SHUTTER,
  ACCESSORY_CATEGORY_SIREN,
  ACCESSORY_CATEGORY_SWITCH,
  ACCESSORY_CATEGORY_WATER_VALVE,
  categoryNames
} from "./accessory-utils";

export const logoCategoryList = [ACCESSORY_CATEGORY_SWITCH, ACCESSORY_CATEGORY_ELECTRICAL_DEVICE, ACCESSORY_CATEGORY_CONTACTOR, ACCESSORY_CATEGORY_WATER_VALVE,
  ACCESSORY_CATEGORY_GAS_VALVE, ACCESSORY_CATEGORY_ELEVATOR, ACCESSORY_CATEGORY_SIREN, ACCESSORY_CATEGORY_SENSOR_DOOR, ACCESSORY_CATEGORY_SENSOR_GAS, ACCESSORY_CATEGORY_SENSOR_LEAK,
  ACCESSORY_CATEGORY_SENSOR_MOTION, ACCESSORY_CATEGORY_SENSOR_SMOKE, ACCESSORY_CATEGORY_SENSOR_WINDOW, ACCESSORY_CATEGORY_SHUTTER]

export function logoCategories() {
  return categoryNames.filter(category => logoCategoryList.includes(category.value))
}