var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        {
          staticStyle: { width: "410px" },
          attrs: { label: _vm.$t("accessory.modbus.device-address") }
        },
        [
          _c("el-input", {
            attrs: { min: "0", type: "number" },
            model: {
              value: _vm.accessoryData.details.device.unitId,
              callback: function($$v) {
                _vm.$set(_vm.accessoryData.details.device, "unitId", $$v)
              },
              expression: "accessoryData.details.device.unitId"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("accessory.modbus.address-type") } },
        [
          _c(
            "el-select",
            {
              on: { change: _vm.onSelectSetOnAddressType },
              model: {
                value: _vm.addressType,
                callback: function($$v) {
                  _vm.addressType = $$v
                },
                expression: "addressType"
              }
            },
            _vm._l(_vm.sensorAddressTypeNames, function(addressType) {
              return _c("el-option", {
                key: addressType.value,
                attrs: { label: addressType.title, value: addressType.value }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticStyle: { width: "410px" },
          attrs: { label: _vm.$t("accessory.modbus.register-address") }
        },
        [
          _c("el-input", {
            attrs: { min: "0", type: "number" },
            model: {
              value: _vm.accessoryData.details.device.statuses.setOn.address,
              callback: function($$v) {
                _vm.$set(
                  _vm.accessoryData.details.device.statuses.setOn,
                  "address",
                  $$v
                )
              },
              expression: "accessoryData.details.device.statuses.setOn.address"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticStyle: { width: "410px" },
          attrs: { label: _vm.$t("accessory.modbus.tripped-value") }
        },
        [
          _c("el-input", {
            attrs: { min: "0", type: "number" },
            on: { change: _vm.onStatusChanged },
            model: {
              value: _vm.statusValue,
              callback: function($$v) {
                _vm.statusValue = $$v
              },
              expression: "statusValue"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }