<template>
  <div :style="{marginLeft: editMode ? (210 + 'px') : (0 + 'px')}">

    <div class="form-item" v-for="(address, index) in accessoryData.details.knxAddresses" :key="index">

      <KnxGroupAddress @changeKnxAddress="onChangeKnxField" :address="address" :enable-write-address="false" :enable-write-value="false" :enable-active-value="false"></KnxGroupAddress>

    </div>


    <div style="margin: 20px 10px 10px 10px;" v-if="!showSelectText">
      <el-form-item :label="$t('accessory.unit')">
        <el-autocomplete
                v-model="unit"
                :fetch-suggestions="queryUnit"
                @select="handleSelect"
                :placeholder="$t('accessory.enter-unit')"
                >
        </el-autocomplete>
      </el-form-item>

    </div>


    <div style="margin: 10px;" v-if="showSelectText">
      <el-form-item prop="details.onValueText" :label="$t('accessory.on-value-text')" :rules="[{required: true}]">
        <el-input v-model="accessoryData.details.onValueText" :placeholder="$t('accessory.enter-status-text')">
        </el-input>
      </el-form-item>

      <el-form-item prop="details.onValueText" :label="$t('accessory.off-value-text')" :rules="[{required: true}]">
        <el-input v-model="accessoryData.details.offValueText" :placeholder="$t('accessory.enter-status-text')">
        </el-input>
      </el-form-item>
    </div>


  </div>
</template>


<script>
  import {knxMixin} from "./knxMixin";
  import KnxGroupAddress from "./KnxGroupAddress"

  export default {
    name: "KnxStatusDisplayInputs",

    components: {
      KnxGroupAddress
    },

    mixins: [knxMixin],

    props: {
      accessoryData: {},
      editMode: {
        type: Boolean,
        default: false
      },
    },

    data: function(){

      return {
        data: {},
        temp: "",

        unit: "",
        units: [{value: "°C"}, {value: "°F"}, {value: "%"}, {value: "lm"}, {value: "g/m3"}],

        errorFields: {}
      }
    },

    computed: {
      showSelectText: function () {
        const address = this.accessoryData.details.knxAddresses.find(addr => addr.variable === "statusDisplay")
        if (address && address.dpt === "1.001" ) {
          return true
        } else {
          return false
        }
      }
    },

    watch: {
      /*accessoryData: {
        deep: true,
        handler: function (val) {
          console.log("accessoryData", val)
          const address = this.accessoryData.details.knxAddresses.find(addr => addr.variable === "statusDisplay")
          if (address && address.dpt === "1.001" ) {

          }
        }
      }*/
    },

    methods: {
      queryUnit(queryString, cb) {
        console.log("queryUnit", queryString)
        var units = this.units;
        var results = queryString ? units.filter(unit => unit.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0) : units;
        // call callback function to return suggestions

        this.accessoryData.unit = queryString
        cb(results);
      },


      handleSelect(item) {
        console.log(item, this.unit);
        this.accessoryData.unit = item.value
      },


      validate(){
        const address = this.accessoryData.details.knxAddresses.find(addr => addr.variable === "statusDisplay")
        if (address && address.dpt === "1.001" ) {
          this.accessoryData.details.oneBit = true
          if (!this.accessoryData.details.onValueText)
            this.accessoryData.details.onValueText = "On"
          if (!this.accessoryData.details.offValueText)
            this.accessoryData.details.offValueText = "Off"
        } else {
          this.accessoryData.details.oneBit = false
          delete this.accessoryData.details.onValueText
          delete this.accessoryData.details.offValueText
        }


        console.log("uuuuuuuuuuunit", this.accessoryData.unit)

        const result = this.accessoryData.details.knxAddresses.some(address => {
          return !this.errorFields.hasOwnProperty(address.variable) || this.errorFields[address.variable] == null
        })

        console.log(this.accessoryData.details.knxAddresses)
        console.log(this.errorFields)

        if (result) {
          this.$message({ type: "error", message: this.$t('accessory.form-rules.fill-all-empty-fields')})
          return false
        }

        var msg = []
        for (const [key, value] of Object.entries(this.errorFields)) {
          if (value)
            msg.push(value)
        }

        if (msg.length > 0) {
          const msgStr = msg.join("<br>")
          this.$message({ type: "error", message: msgStr, dangerouslyUseHTMLString: true})
          return false
        }

        return true
      },

      onChangeKnxField(variable, msg){
        console.log("onChangeKnxField", variable, msg)
        this.errorFields[variable] = msg
      }
    },

    created(){
      console.log("accessory", this.accessoryData)

      if (!this.accessoryData.details.knxAddresses) {
        this.accessoryData.details = {
          knxAddresses: [
            {
              variable: "statusDisplay",
              dpt: "1.001",
              readAddress: ""
            }
          ],
          oneBit: true,
          onValueText: "On",
          offValueText: "Off"
        }
      }

      if (!this.accessoryData.unit) {
        this.accessoryData.unit = ""
      } else {
        this.unit = this.accessoryData.unit
      }
    }
  }
</script>

<style scoped>
  .line {
    height: 1px;
    margin: 0px 0px 20px 0px;
    background-color: #eaeaea;
  }
  .form-item {
    margin: 10px;
    padding: 10px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    color: #606266;
  }
  .form-item span {
    font-weight: bold;
  }
</style>