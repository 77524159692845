<template>
    <div>
        <el-form-item style="width: 410px"  :label="$t('accessory.modbus.device-address')">
            <el-input min="0" type="number" v-model="accessoryData.details.device.unitId"/>
        </el-form-item>

        <el-form-item :label="$t('accessory.modbus.address-type')">
            <el-select  v-model="addressType" @change="onSelectSetOnAddressType">
                <el-option v-for="addressType in sensorAddressTypeNames" :label="addressType.title" :value="addressType.value" :key="addressType.value">
                </el-option>
            </el-select>
        </el-form-item>
        
        <el-form-item style="width: 410px" :label="$t('accessory.modbus.register-address')">
            <el-input min="0" type="number" v-model="accessoryData.details.device.statuses.setOn.address"/>
        </el-form-item>   

        <el-form-item style="width: 410px" :label="$t('accessory.modbus.tripped-value')">
            <el-input min="0" type="number" @change="onStatusChanged" v-model="statusValue"/>
        </el-form-item>
    </div>
</template>

<script>
  import {ADDRESS_TYPE_HOLDING_REGISTER, ADDRESS_TYPE_COIL, ADDRESS_TYPE_DISCRETE_INPUT, sensorAddressTypeList} from "@/utils/modbus";

    export default {
        name: "ModbusSensorInputs",
        data: function(){
        return {
            ADDRESS_TYPE_HOLDING_REGISTER,
            ADDRESS_TYPE_COIL,
            ADDRESS_TYPE_DISCRETE_INPUT,
            statusValue: 1,
            addressType: 0,
            onValue: 'onValue' in this.accessoryData ? this.accessoryData.onValue : true,
            pulse: 'pulse' in this.accessoryData ? this.accessoryData.pulse : false,
            pulseDuration: 'pulseDuration' in this.accessoryData ? this.accessoryData.pulseDuration : 50,
            }
        },

        props: {
            accessoryData: {}
        },    

        computed: {
            sensorAddressTypeNames(){
                return sensorAddressTypeList
            }
        },   

        methods:{

            onStatusChanged(value){

                if(this.addressType == ADDRESS_TYPE_COIL || this.addressType == ADDRESS_TYPE_DISCRETE_INPUT){
                    if(value > 1) value = 1
                    else if(value < 0) value = 0
                }

                this.statusValue = value
                this.accessoryData.details.device.statuses.setOn.statusValue = this.statusValue
            },
            onSelectSetOnAddressType(value){
                this.accessoryData.details.device.statuses.setOn.addressType = value
            },

        },
        
        created(){
            if(!this.accessoryData.details.device){
                this.accessoryData.details.device = {}

                this.accessoryData.details.device.unitId = 1

                this.accessoryData.details.device.statuses = {}
                this.accessoryData.details.device.statuses.setOn = {}

                this.accessoryData.details.device.statuses.setOn.address = 1
                this.accessoryData.details.device.statuses.setOn.addressType = 0
                this.accessoryData.details.device.statuses.setOn.statusValue = 1

                this.accessoryData.details.device.statuses.setOn.operationName = "HHModbusStatus"

                if (!this.accessoryData.hasOwnProperty('onValue'))
                    this.accessoryData.onValue = this.onValue

                if (!this.accessoryData.hasOwnProperty('pulse'))
                    this.accessoryData.pulse = this.pulse

                if (!this.accessoryData.hasOwnProperty('pulseDuration'))
                    this.accessoryData.pulseDuration = this.pulseDuration
            }
            else{
                this.addressType = this.accessoryData.details.device.statuses.setOn.addressType
            }

        }        
    }
</script>

<style scoped>

</style>