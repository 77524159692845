<template>
  <div :style="{marginLeft: editMode ? (210 + 'px') : (0 + 'px')}">

    <el-form-item prop="onValue" :label="$t('accessory.normally')" style="width: 400px">
      <el-switch v-model="onValue"></el-switch>
    </el-form-item>

    <el-form-item prop="pulse" :label="$t('accessory.pulse')" style="width: 400px">
      <el-switch v-model="pulse"></el-switch>
    </el-form-item>

    <el-form-item v-show="pulse" :label="$t('accessory.duration')" prop="pulseDuration">
      <el-input-number v-model="pulseDuration" :min="100" :step="100">
      </el-input-number>
      <span style="margin-left: 10px;">ms.</span>
    </el-form-item>

    <div class="form-item" v-for="(address, index) in accessoryData.details.knxAddresses" :key="index">

      <el-form-item prop="address" label-width="0" style="width: 100%">

        <span>{{ title(address.variable) }}</span>
        <div class="line"></div>

        <el-row :gutter="innerSpace">
          <el-col v-if="showWriteAddress(address.variable)" :span="3">{{ $t('accessory.write') }}</el-col>
          <el-col v-if="showWriteAddress(address.variable)" :span="6">
            <el-input type="text" v-model="address.writeAddress" placeholder=".../.../..." v-mask="groupAddressMask">
            </el-input>
          </el-col>
          <el-col v-if="showReadAddress(address.variable)" :span="3">{{ $t('accessory.read') }}</el-col>
          <el-col v-if="showReadAddress(address.variable)" :span="6">
            <el-input type="text" v-model="address.readAddress" placeholder=".../.../..." v-mask="groupAddressMask">
            </el-input>
          </el-col>
        </el-row>

      </el-form-item>

    </div>

  </div>
</template>


<script>
  import {knxMixin} from "./knxMixin";

  export default {
    name: "KnxSwitchInputs",

    mixins: [knxMixin],

    props: {
      accessoryData: {},
      editMode: {
        type: Boolean,
        default: false
      }
    },

    data: function(){
      return {
        data: {},
        temp: "",
        onValue: 'onValue' in this.accessoryData ? this.accessoryData.onValue : true,
        pulse: 'pulse' in this.accessoryData ? this.accessoryData.pulse : false,
        pulseDuration: 'pulseDuration' in this.accessoryData ? this.accessoryData.pulseDuration : 50,
      }
    },

    watch: {
      onValue: function(value){
        this.accessoryData.onValue = value
      },

      pulse: function(value){
        this.accessoryData.pulse = value
      },

      pulseDuration: function(value){
        this.accessoryData.pulseDuration = value
      },
    },

    methods: {
      validate(){
        const failAddresses = this.accessoryData.details.knxAddresses.filter(address => {
          return (this.showReadAddress(address.variable) && !this.validateKnxGroupAddress(address.readAddress))
            || (this.showWriteAddress(address.variable) && !this.validateKnxGroupAddress(address.writeAddress))
        })

        if (failAddresses.length > 0) {
          this.$message({ type: "error", message: this.$t('accessory.form-rules.knx-address-invalid')})
          return false
        } else {
          return true
        }
      }
    },

    created(){
      console.log("accessory", this.accessoryData)

      if (!this.accessoryData.hasOwnProperty('onValue'))
        this.accessoryData.onValue = this.onValue

      if (!this.accessoryData.hasOwnProperty('pulse'))
        this.accessoryData.pulse = this.pulse

      if (!this.accessoryData.hasOwnProperty('pulseDuration'))
        this.accessoryData.pulseDuration = this.pulseDuration

      if (!this.accessoryData.details.knxAddresses) {
        this.accessoryData.details = {
          knxAddresses: [
            {
              variable: "onOffState",
              writeAddress: "",
              readAddress: ""
            }
          ]
        }
      }
    }
  }
</script>

<style scoped>
  .line {
    height: 1px;
    margin: 0px 0px 20px 0px;
    background-color: #eaeaea;
  }
  .form-item {
    margin: 10px;
    padding: 10px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    color: #606266;
  }
  .form-item span {
    font-weight: bold;
  }
</style>