<template>
  <div :style="{ marginLeft: editMode ? 210 + 'px' : 0 + 'px' }">
    <div
      class="form-item"
      v-for="(address, index) in knxAddresses"
      :key="index"
    >
      <el-form-item prop="address" label-width="0" style="width: 100%">
        <div class="item-container">
          <el-checkbox
            v-if="address.optional"
            v-model="selectedList[address.optionalModel]"
            :value="false"
            @change="onOptionalAddressChanged(address, $event)"
            style="margin-left: 5px"
            >{{ address.title }}</el-checkbox
          >
          <span v-else>{{ address.title }}</span>

          <div
            v-if="
              (address.optional && selectedList[address.optionalModel]) ||
                !address.optional
            "
            class="mode-container"
          >
            <KnxGroupAddress
              @changeKnxAddress="onChangeKnxField"
              :address="address"
              :enableDpt="false"
              :enableWriteAddress="address.showWriteAddress"
              :enableReadAddress="address.showReadAddress"
              :enableActiveValue="address.showActiveValue"
              :enableWriteValue="address.showWriteValue"
            >
            </KnxGroupAddress>
          </div>
        </div>
      </el-form-item>
    </div>
  </div>
</template>

<script>
import { knxMixin } from "./knxMixin";
import KnxGroupAddress from "./KnxGroupAddress";

export default {
  name: "KnxAudioInputs",

  mixins: [knxMixin],

  components: { KnxGroupAddress },

  data: function() {
    return {
      knxAddresses: [
        {
          variable: "play",
          dpt: "1.001",
          writeAddress: "",
          readAddress: "",
          showWriteAddress: true,
          showReadAddress: true,
          activeValue: "1",
          showActiveValue: false,
          showWriteValue: false,
          optional: false,
          title: this.$t("accessory.knx.audio-play"),
        },
        {
          variable: "volume",
          dpt: "5.001",
          writeAddress: "",
          readAddress: "",
          showWriteAddress: true,
          showReadAddress: true,
          showActiveValue: false,
          showWriteValue: false,
          optional: true,
          optionalModel: "volumeAvailable",
          title: this.$t("accessory.knx.audio-volume"),
        },
        {
          variable: "mute",
          dpt: "1.001",
          writeAddress: "",
          readAddress: "",
          showWriteAddress: true,
          showReadAddress: true,
          activeValue: "1",
          showActiveValue: false,
          showWriteValue: false,
          optional: true,
          optionalModel: "muteAvailable",
          title: this.$t("accessory.knx.audio-mute"),
        },
        {
          variable: "previous",
          dpt: "1.001",
          writeAddress: "",
          readAddress: "",
          showWriteAddress: true,
          showReadAddress: false,
          writeValue: "1",
          showActiveValue: false,
          showWriteValue: true,
          optional: false,
          title: this.$t("accessory.knx.audio-prev"),
        },
        {
          variable: "next",
          dpt: "1.001",
          writeAddress: "",
          readAddress: "",
          showWriteAddress: true,
          showReadAddress: false,
          writeValue: "1",
          showActiveValue: false,
          showWriteValue: true,
          optional: false,
          title: this.$t("accessory.knx.audio-next"),
        },
        {
          variable: "track",
          dpt: "16.001",
          writeAddress: "",
          readAddress: "",
          showWriteAddress: false,
          showReadAddress: true,
          showActiveValue: false,
          showWriteValue: false,
          optional: true,
          optionalModel: "trackAvailable",
          title: this.$t("accessory.knx.audio-track"),
        },
        {
          variable: "album",
          dpt: "16.001",
          writeAddress: "",
          readAddress: "",
          showWriteAddress: false,
          showReadAddress: true,
          showActiveValue: false,
          showWriteValue: false,
          optional: true,
          optionalModel: "albumAvailable",
          title: this.$t("accessory.knx.audio-album"),
        },
        {
          variable: "artist",
          dpt: "16.001",
          writeAddress: "",
          readAddress: "",
          showWriteAddress: false,
          showReadAddress: true,
          showActiveValue: false,
          showWriteValue: false,
          optional: true,
          optionalModel: "artistAvailable",
          title: this.$t("accessory.knx.audio-artist"),
        },
        {
          variable: "playlist",
          dpt: "16.001",
          writeAddress: "",
          readAddress: "",
          showWriteAddress: false,
          showReadAddress: true,
          showActiveValue: false,
          showWriteValue: false,
          optional: true,
          optionalModel: "playlistAvailable",
          title: this.$t("accessory.knx.audio-playlist"),
        },
        {
          variable: "shuffle",
          dpt: "1.001",
          writeAddress: "",
          readAddress: "",
          showWriteAddress: true,
          showReadAddress: true,
          activeValue: "1",
          showActiveValue: false,
          showWriteValue: false,
          optional: true,
          optionalModel: "shuffleAvailable",
          title: this.$t("accessory.knx.audio-shuffle"),
        },
        {
          variable: "repeat",
          dpt: "1.001",
          writeAddress: "",
          readAddress: "",
          showWriteAddress: true,
          showReadAddress: true,
          activeValue: "1",
          showActiveValue: false,
          showWriteValue: false,
          optional: true,
          optionalModel: "repeatAvailable",
          title: this.$t("accessory.knx.audio-repeat"),
        },
      ],
      selectedList: {
        volumeAvailable: false,
        muteAvailable: false,
        trackAvailable: false,
        albumAvailable: false,
        artistAvailable: false,
        playlistAvailable: false,
        shuffleAvailable: false,
        repeatAvailable: false,
      },
      errorFields: {},
    };
  },

  props: {
    accessoryData: {},
    editMode: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    knxAddresses: {
      handler: function(val) {
        // val.forEach((address) => {
        //   var knxAddress = this.accessoryData.details.knxAddresses.find(
        //     (knxAddress) => knxAddress.variable === address.variable
        //   );
        //   if (knxAddress) {
        //     this.accessoryData.details.knxAddresses[
        //       this.accessoryData.details.knxAddresses.indexOf(knxAddress)
        //     ] = this.copyAddress(address);

        //     this.accessoryData.details.knxAddresses = this.accessoryData.details.knxAddresses;
        //   }
        // });

        this.accessoryData.details.knxAddresses = val
          .filter((address) =>
            this.accessoryData.details.knxAddresses.find(
              (addr) => addr.variable === address.variable
            )
          )
          .map((address) => this.copyAddress(address));

        console.log("WatchKnxAddresses", val);
      },
      deep: true,
    },
    selectedList: {
      handler: function(val) {
        for (const item in val) {
          this.accessoryData[item] = this.selectedList[item];
        }
        console.log("accessoryData", this.accessoryData);
      },
      deep: true,
    },
  },

  methods: {
    onChangeKnxField(variable, message) {
      console.log("OnChangeKnxField", variable, message);
      this.errorFields[variable] = message;
    },
    copyAddress(address) {
      const cloneAddress = { ...address };
      delete cloneAddress.showWriteAddress;
      delete cloneAddress.showReadAddress;
      delete cloneAddress.showActiveValue;
      delete cloneAddress.showWriteValue;
      delete cloneAddress.optional;
      delete cloneAddress.optionalModel;
      delete cloneAddress.title;
      return cloneAddress;
    },
    onOptionalAddressChanged(addr, value) {
      const variable = addr.variable;

      //this.selectedList[addr.optionalModel] = value;
      console.log("onOptionalAddressChanged", variable, value);

      var address = this.accessoryData.details.knxAddresses.find((address) => {
        return address.variable === variable;
      });

      if (
        this.knxAddresses.find((address) => address.variable === variable)
          .optional
      ) {
        var cachedKnxAddress = {
          ...this.knxAddresses.find((address) => address.variable === variable),
        };

        if (address) cachedKnxAddress = address;

        if (value) {
          this.accessoryData.details.knxAddresses.push(cachedKnxAddress);
        } else {
          this.accessoryData.details.knxAddresses.splice(
            this.accessoryData.details.knxAddresses.indexOf(address),
            1
          );
        }
      }
    },
    validate() {
      // const result = this.accessoryData.details.knxAddresses.some((address) => {
      //   return (
      //     !this.errorFields.hasOwnProperty(address.variable) ||
      //     this.errorFields[address.variable] == null
      //   );
      // });

      // console.log(this.accessoryData.details.knxAddresses);
      // console.log(this.errorFields);

      // if (result) {
      //   this.$message({
      //     type: "error",
      //     message: this.$t("accessory.form-rules.fill-all-empty-fields"),
      //   });
      //   return false;
      // }

      // var msg = [];
      // for (const [key, value] of Object.entries(this.errorFields)) {
      //   if (value) msg.push(value);
      // }

      // if (msg.length > 0) {
      //   const msgStr = msg.join("<br>");
      //   this.$message({
      //     type: "error",
      //     message: msgStr,
      //     dangerouslyUseHTMLString: true,
      //   });
      //   return false;
      // }

      return true;
    },
  },

  created() {
    // if (!this.accessoryData.hasOwnProperty("volumeAvailable"))
    //   this.accessoryData.volumeAvailable = this.selectedList.volume;
    // if (!this.accessoryData.hasOwnProperty("muteAvailable"))
    //   this.accessoryData.muteAvailable = this.selectedList.mute;
    // if (!this.accessoryData.hasOwnProperty("trackAvailable"))
    //   this.accessoryData.trackAvailable = this.selectedList.track;
    // if (!this.accessoryData.hasOwnProperty("albumAvailable"))
    //   this.accessoryData.albumAvailable = this.selectedList.album;
    // if (!this.accessoryData.hasOwnProperty("artistAvailable"))
    //   this.accessoryData.artistAvailable = this.selectedList.artist;
    // if (!this.accessoryData.hasOwnProperty("playlistAvailable"))
    //   this.accessoryData.playlistAvailable = this.selectedList.playlist;
    // if (!this.accessoryData.hasOwnProperty("shuffleAvailable"))
    //   this.accessoryData.shuffleAvailable = this.selectedList.shuffle;
    // if (!this.accessoryData.hasOwnProperty("repeatAvailable"))
    //   this.accessoryData.repeatAvailable = this.selectedList.repeat;

    console.log("AccKnxAddresses", this.accessoryData.details.knxAddresses);

    this.knxAddresses
      .filter((address) => address.optional)
      .forEach((address) => {
        //this.selectedList[address.optionalModel] = false;

        if (!this.accessoryData.hasOwnProperty(address.optionalModel)) {
          this.accessoryData[address.optionalModel] = false;
          //this.selectedList[address.optionalModel] = true;
        } else if (this.accessoryData[address.optionalModel] == true) {
          this.selectedList[address.optionalModel] = true;
        }
      });

    if (!this.accessoryData.details.knxAddresses) {
      this.accessoryData.details = {
        knxAddresses: JSON.parse(
          JSON.stringify(
            this.knxAddresses.map((address) => this.copyAddress(address))
          )
        ),
      };
    } else {
      this.accessoryData.details.knxAddresses.forEach((addr) => {
        var address = this.knxAddresses.find(
          (address) => address.variable == addr.variable
        );
        if (address) {
          address.writeAddress = addr.writeAddress;
          address.readAddress = addr.readAddress;
        }
      });

      // this.selectedList = {};
      // this.accessoryData.details.knxAddresses.forEach((address) => {
      //   if (this.knxAddresses.find(addr => addr.variable == address.variable).optional)
      //     this.selectedList[address.variable + "Available"] = true;
      // });
    }

    console.log("KnxAddresses", this.knxAddresses);
  },
};
</script>

<style scoped>
.line {
  height: 1px;
  margin: 0px 0px 20px 0px;
  background-color: #eaeaea;
}
.form-item {
  margin: 10px;
  padding: 10px;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  color: #606266;
}
.form-item span {
  font-weight: bold;
}
.item-container {
  margin-top: 0px;
  padding: 0px;
}

.mode-container {
  padding: 0px;
  margin-top: 0px;
}
</style>
