<template>
  <div :style="{marginLeft: editMode ? (210 + 'px') : (0 + 'px')}">
    <el-tabs v-model="tabValue" type="card" closable @tab-remove="removeTab">

      <!--------------------------------------------------------------------------------------------------------------->
      <!------------------------------------------- Temperature Tab Contents ------------------------------------------>
      <!--------------------------------------------------------------------------------------------------------------->
      <el-tab-pane name="temperature">
        <span slot="label">Temperature</span>


        <div class="item-container">
          <el-checkbox v-model="onStateEnabled" @change="onOptionalAddressChanged('actionOn', $event)" style="margin-left: 5px">On State</el-checkbox>
          <div v-if="onStateEnabled" class="mode-container">
            <KnxGroupAddress @changeKnxAddress="onChangeKnxField" :address="knxAddress('actionOn')"></KnxGroupAddress>
          </div>
        </div>

        <div class="item-container">
          <el-checkbox v-model="offStateEnabled" @change="onOptionalAddressChanged('actionOff', $event)" style="margin-left: 5px">Off State</el-checkbox>
          <div v-if="offStateEnabled" class="mode-container">
            <KnxGroupAddress @changeKnxAddress="onChangeKnxField" :address="knxAddress('actionOff')"></KnxGroupAddress>
          </div>
        </div>


        <div class="item-container">
          <el-checkbox v-model="temperatureEnabled" @change="onOptionalAddressChanged('temperature', $event)" style="margin-left: 5px">Room Temperature</el-checkbox>
          <div v-if="temperatureEnabled" class="mode-container">
            <KnxGroupAddress @changeKnxAddress="onChangeKnxField" 
                             :enable-write-value="false" 
                             :enable-write-address="false" 
                             :enable-active-value="false"
                             :address="knxAddress('temperature')"></KnxGroupAddress>
          </div>
        </div>

        <div class="item-container">
          <span style="margin-left: 5px">Target Set Point</span>

          <el-select style="margin-left: 10px" size="mini" v-model="setPointType" placeholder="Type">
            <el-option label="Direct SetPoint" value="direct"></el-option>
            <el-option label="Shift SetPoint (DPT 6 - 1 Byte)" value="shift"></el-option>
            <el-option label="Shift SetPoint (DPT 9 - 2 Byte)" value="shiftOffsetC"></el-option>
          </el-select>

          <div class="mode-container">

            <KnxGroupAddress @changeKnxAddress="onChangeKnxField"
                    :enable-write-address="setPointType == 'direct'"
                    :enable-write-value="false"
                    :enable-active-value="false"
                    :address="knxAddress('setPoint')">
            </KnxGroupAddress>

            <span>Min<el-input v-model="minSetPoint" class="number-input" size="mini" type="number" placeholder="Min"></el-input>
            </span>

            <span>Max<el-input v-model="maxSetPoint" class="number-input" size="mini" type="number" placeholder="Max"></el-input>
            </span>

            <span>Step<el-input v-model="setPointStep" class="number-input" size="mini" type="number" placeholder="Step"></el-input>
            </span>

          </div>
        </div>

        <div class="item-container" v-if="setPointType != 'direct'">
          <span style="margin-left: 5px">Shift</span>

          <div class="mode-container">
            <KnxGroupAddress @changeKnxAddress="onChangeKnxField"
                    :enable-write-value="false"
                    :enable-active-value="false"
                    :enable-dpt="false"
                    :address="knxAddress('setPointShift')">
            </KnxGroupAddress>
          </div>

        </div>

        <div class="item-container">
          <el-checkbox v-model="heatingIndicationEnabled" @change="onOptionalAddressChanged('heatingIndication', $event)" style="margin-left: 5px">Heating Indication</el-checkbox>
          <div v-if="heatingIndicationEnabled" class="mode-container">
            <KnxGroupAddress @changeKnxAddress="onChangeKnxField" 
                             :enable-write-value="false" 
                             :enable-write-address="false" 
                             :enable-active-value="true"
                             :disabledDpt="true"
                             :address="knxAddress('heatingIndication')"></KnxGroupAddress>
          </div>
        </div>

        <div class="item-container">
          <el-checkbox v-model="coolingIndicationEnabled" @change="onOptionalAddressChanged('coolingIndication', $event)" style="margin-left: 5px">Cooling Indication</el-checkbox>
          <div v-if="coolingIndicationEnabled" class="mode-container">
            <KnxGroupAddress @changeKnxAddress="onChangeKnxField" 
                             :enable-write-value="false" 
                             :enable-write-address="false" 
                             :enable-active-value="true"
                             :disabledDpt="true"
                             :address="knxAddress('coolingIndication')"></KnxGroupAddress>
          </div>
        </div>

        <div class="item-container">
          <el-checkbox v-model="co2Enabled" @change="onOptionalAddressChanged('CO2forTemperature', $event)" style="margin-left: 5px">CO2</el-checkbox>
          <div v-if="co2Enabled" class="mode-container">
            <KnxGroupAddress @changeKnxAddress="onChangeKnxField" 
                             :enable-write-value="false" 
                             :enable-write-address="false" 
                             :enable-active-value="false"
                             :disabledDpt="true"
                             :address="knxAddress('CO2forTemperature')"></KnxGroupAddress>
          </div>
        </div>

        <div class="item-container">
          <el-checkbox v-model="humidityEnabled" @change="onOptionalAddressChanged('humidityForTemperature', $event)" style="margin-left: 5px">Humidity</el-checkbox>
          <div v-if="humidityEnabled" class="mode-container">
            <KnxGroupAddress @changeKnxAddress="onChangeKnxField" 
                             :enable-write-value="false" 
                             :enable-write-address="false" 
                             :enable-active-value="false"
                             :disabledDpt="true"
                             :address="knxAddress('humidityForTemperature')"></KnxGroupAddress>
          </div>
        </div>

        <div class="item-container">
          <el-checkbox v-model="airQualityEnabled" @change="onOptionalAddressChanged('airQualityForTemperature', $event)" style="margin-left: 5px">Air Quality</el-checkbox>
          <div v-if="airQualityEnabled" class="mode-container">
            <KnxGroupAddress @changeKnxAddress="onChangeKnxField" 
                             :enable-write-value="false" 
                             :enable-write-address="false" 
                             :enable-active-value="false"
                             :disabledDpt="true"
                             :address="knxAddress('airQualityForTemperature')"></KnxGroupAddress>
          </div>
        </div>


      </el-tab-pane>


      <!--------------------------------------------------------------------------------------------------------------->
      <!------------------------------------------- Mode Tab Contents ------------------------------------------------->
      <!--------------------------------------------------------------------------------------------------------------->

      <el-tab-pane v-for="(component, index) in accessoryData.details.components" :key="component.type" :name="component.type">
        <span slot="label">{{ titleTab(component.type) }}</span>

          <!-- <div v-if="component.type == 'humidity'">
            <div class="mode-container">
            <KnxGroupAddress @changeKnxAddress="onChangeKnxField"
                    :enableWriteAddress="false"
                    :enable-active-value="false"
                    :enableWriteValue="false"
                    :enable-dpt="false"
                    :address="knxAddress('humidity')">
            </KnxGroupAddress>
            </div>
          </div>
          <div v-else-if="component.type == 'CO2'">
            <div class="mode-container">
            <KnxGroupAddress @changeKnxAddress="onChangeKnxField"
                    :enableWriteAddress="false"
                    :enable-active-value="false"
                    :enableWriteValue="false"
                    :enable-dpt="false"
                    :address="knxAddress('CO2')">
            </KnxGroupAddress>
            </div>
          </div> -->
          <div>
        <!--Component Title-->
        <span style="margin-left: 15px">
          Title
          <el-input style="width: 160px; margin-left: 10px;margin-top: 10px" size="small" type="text" v-model="component.title" placeholder="Title"></el-input>
        </span>



        <!--Component Modes-->
        <div v-for="(mode, index) in components.find(comp => comp.type === component.type).modes" :key="mode.type" class="item-container">

          <!--Mode Selection-->
          <el-checkbox v-model="checkedList[mode.type]" @change="onCheckedMode(component.type, mode.type)" style="margin-left: 5px">
            {{ mode.title }}
          </el-checkbox>


          <div class="line" v-if="!checkedList[mode.type]"></div>


          <!--Mode Content-->
          <div v-if="checkedList[mode.type]" class="mode-container">

            <span style="display: flex; flex-direction: row; align-items: center">
              Title


              <!--Mode Icon-->
              <el-popover v-if="false" placement="right" v-model="visibleIcons[mode.type]">

                <div v-for="(row, index) in modeIcons" :key="index" style="display: flex; flex-direction: row">
                  <div v-for="(icon, index) in row" :key="icon.value" style="display: flex; flex-direction: column">
                    <span @click="onSelectIcon(component.type, mode.type, icon)">
                      <img :src="'/assets/icons/' + icon.value + '.png'" class="selectable-icon">
                    </span>

                  </div>
                </div>

                <img slot="reference" :src="'/assets/icons/' + iconMode(component.type, mode.type) + '.png'" class="icon-contact">
              </el-popover>

              <image-picker :images="modeIcons[component.type]" :selected-image="iconMode(component.type, mode.type)" placement="right" @selectImage="onSelectImage(component.type, mode.type, $event)"></image-picker>


              <!--Mode Title-->
              <el-input style="width: 160px; margin-left: 10px;" size="mini" type="text"
                        :value="titleTabContent(component.type, mode.type)" @input="onChangeModeName($event, component.type, mode.type)" placeholder="Title">
              </el-input>

              <el-button size="mini" style="margin-left: 10px" @click="applyAll(component.type, knxAddress(mode.type))"><i class="fa fa-copy"></i></el-button>
            </span>


            <!--Mode Group Address-->
            <KnxGroupAddress @changeKnxAddress="onChangeKnxField" :address="knxAddress(mode.type)"></KnxGroupAddress>

          </div>


        </div>
      </div>
      </el-tab-pane>




      <!--------------------------------------------------------------------------------------------------------------->
      <!------------------------------------------- Add Tab Contents -------------------------------------------------->
      <!--------------------------------------------------------------------------------------------------------------->


      <el-tab-pane v-if="addableTabs.length > 0" name="addTab">

        <el-popover title="Select Mode" width="300" slot="label" placement="top" v-model="visibleAddTab">


          <el-table :data="addableTabs" :show-header="false">

            <el-table-column prop="title" width="200"></el-table-column>

            <el-table-column>
              <template slot-scope="scope">
                <el-button size="mini" @click="addTab(scope.row)">Select</el-button>
                <span v-if="false" @click="addTab(scope.row)"><i class="far fa-edit"></i></span>
              </template>
            </el-table-column>


          </el-table>

          <span slot="reference"> <i class="fa fa-plus" style="margin: 10px"></i> </span>

        </el-popover>

      </el-tab-pane>




    </el-tabs>
  </div>
</template>

<script>
  import {knxMixin} from "./knxMixin";
  import {accessoryMixin} from "../accessoryMixin";
  import KnxGroupAddress from "./KnxGroupAddress"
  import ImagePicker from '../ImagePicker'

  const components = [
    { title: "Operation Mode", type: "modeOpp", modes: [
        { title: "Auto", type: "modeOppAuto", icon: "ic_operation_auto" },
        { title: "Heat", type: "modeOppHeat", icon: "ic_operation_heat" },
        { title: "Cool", type: "modeOppCool", icon: "ic_operation_cool" },
        { title: "Fan", type: "modeOppFan", icon: "ic_operation_fan" },
        { title: "Dry", type: "modeOppDry", icon: "ic_operation_dry" }
      ]
    },
    { title: "Control Mode", type: "modeControl", modes:[
        { title: "Auto", type: "modeControlAuto", icon: "ic_control_auto" },
        { title: "Comfort", type: "modeControlComfort", icon: "ic_control_comfort" },
        { title: "Standby", type: "modeControlStandby", icon: "ic_control_standby" },
        { title: "Night", type: "modeControlNight", icon: "ic_control_night" },
        { title: "Protection", type: "modeControlProtection", icon: "ic_control_protection" },
      ]
    },
    { title: "Fan Mode", type: "modeFan", modes: [
        { title: "Auto", type: "modeFanAuto", icon: "ic_fan_auto" },
        { title: "Lowest", type: "modeFanLowest", icon: "ic_fan_1" },
        { title: "Lower", type: "modeFanLower", icon: "ic_fan_2" },
        { title: "Middle", type: "modeFanMiddle", icon: "ic_fan_3" },
        { title: "Higher", type: "modeFanHigher", icon: "ic_fan_4" },
        { title: "Highest", type: "modeFanHighest", icon: "ic_fan_5" },
      ]
    },
    { title: "U-D Vane", type: "modeUpDown", modes: [
        { title: "Auto", type: "modeUpDownAuto", icon: "ic_updown_auto" },
        { title: "Position 1", type: "modeUpDownPosition1", icon: "ic_updown_p1" },
        { title: "Position 2", type: "modeUpDownPosition2", icon: "ic_updown_p2" },
        { title: "Position 3", type: "modeUpDownPosition3", icon: "ic_updown_p3" },
        { title: "Position 4", type: "modeUpDownPosition4", icon: "ic_updown_p4" },
        { title: "Position 5", type: "modeUpDownPosition5", icon: "ic_updown_p5" },
        { title: "Swing", type: "modeUpDownSwing", icon: "ic_updown_swing" },
      ]
    },
    { title: "L-R Vane", type: "modeLeftRight", modes: [
        { title: "Auto", type: "modeLeftRightAuto", icon: "ic_leftright_auto" },
        { title: "Position 1", type: "modeLeftRightPosition1", icon: "ic_leftright_p1" },
        { title: "Position 2", type: "modeLeftRightPosition2", icon: "ic_leftright_p2" },
        { title: "Position 3", type: "modeLeftRightPosition3", icon: "ic_leftright_p3" },
        { title: "Position 4", type: "modeLeftRightPosition4", icon: "ic_leftright_p4" },
        { title: "Position 5", type: "modeLeftRightPosition5", icon: "ic_leftright_p5" },
        { title: "Swing", type: "modeLeftRightSwing", icon: "ic_leftright_swing" },
      ]
    },
    // { title: "Humidity", type: "humidity", modes: [
    //   ]
    // },
    // { title: "CO2", type: "CO2", modes: [
    //   ]
    // },
  ]

  export default {
    name: "KnxAdvThermostatInputs",

    mixins: [knxMixin, accessoryMixin],

    components: {
      KnxGroupAddress,
      ImagePicker
    },

    props: {
      accessoryData: {},
      editMode: {
        type: Boolean,
        default: false
      }
    },

    data: function () {
      return {
        modeIcons: {
          modeOpp: ['ic_operation_auto', 'ic_operation_heat', 'ic_operation_cool', 'ic_operation_fan', 'ic_operation_dry'],
          modeControl: ['ic_control_auto', 'ic_control_comfort', 'ic_control_standby', 'ic_control_night', 'ic_control_protection'],
          modeFan: ['ic_fan_auto', 'ic_fan_1', 'ic_fan_2', 'ic_fan_3', 'ic_fan_4', 'ic_fan_5'],
          modeUpDown: ['ic_updown_auto', 'ic_updown_p1', 'ic_updown_p2', 'ic_updown_p3', 'ic_updown_p4', 'ic_updown_p5', 'ic_updown_swing'],
          modeLeftRight: ['ic_leftright_auto', 'ic_leftright_p1', 'ic_leftright_p2', 'ic_leftright_p3', 'ic_leftright_p4', 'ic_leftright_p5', 'ic_leftright_swing'],
        },

        address: {
          dpt: "1.001",
          writeAddress: "",
          readAddress: "",
          writeValue: "",
          activeValue: ""
        },
        visibleAddTab: false,
        visibleIcons: {},

        tabValue: "temperature",
        tabIndex: 1,

        minSetPoint: 5,
        maxSetPoint: 40,
        setPointStep: 1,

        onStateEnabled: true,
        offStateEnabled: true,
        temperatureEnabled: true,
        heatingIndicationEnabled: false,
        coolingIndicationEnabled: false,
        co2Enabled: false,
        humidityEnabled: false,
        airQualityEnabled: false,

        cachedActionOnAddress: null,
        cachedActionOffAddress: null,
        cachedTemperatureAddress: null,
        cachedHeatingIndicationAddress: null,
        cachedCoolingIndicationAddress: null,
        cachedCO2Address: null,
        cachedHumidityAddress: null,
        cachedAirQualityAddress: null,
        cachedCO2ForTemperatureAddress: null,
        cachedHumidityForTemperatureAddress: null,
        cachedAirQualityForTemperatureAddress: null,

        setPointType: "direct",

        checkedList: {},

        errorFields: {}

      }
    },

    watch: {
      accessoryData: {
        handler: function (val) {
          console.log(val)
        },
        deep: true
      },

      minSetPoint: function (val) {
        this.updateSetPoints()
      },
      maxSetPoint: function (val) {
        this.updateSetPoints()
      },
      setPointStep: function (val) {
        this.updateSetPoints()
      },
      setPointType: function (val) {
        this.accessoryData.details.setPointType = val

        if (val !== "direct") {
          var address = this.accessoryData.details.knxAddresses.find(address => address.variable === "setPointShift")
          if (address) {
            address.dpt = val === "shift" ? "6.001" : "9.001"
          }
        }
      },
    },

    computed: {

      components: function(){
        return components
      },

      addableTabs: function () {
        var addableTabs = []
        components.forEach(component => {
          let tab = this.accessoryData.details.components.find(tab => tab.type == component.type)

          if (!tab) {
            addableTabs.push(JSON.parse(JSON.stringify(component)))
          }
        })

        return addableTabs
      },

      modeIcons: function () {
        var modeIcons = new Array()
        var row = new Array()


        this.icons.forEach((icon, index) => {

          if (row.length == 5) {
            modeIcons.push(row)
            row = new Array()
          } else {
            row.push(icon)
          }
        })

        console.log("mode icons", modeIcons)

        return modeIcons
      }
    },

    methods: {

      onOptionalAddressChanged(variable, status){

          if(variable === "actionOn" || variable === "actionOff"){
            this.onStateEnabled = status;
            this.offStateEnabled = status;
          }

          if(status){
            this.knxAddress(variable)
            if(variable === "actionOn"){
              this.knxAddress("actionOff")
            }
            else if(variable === "actionOff"){
              this.knxAddress("actionOn")
            }
          }
          else{
            var address = this.accessoryData.details.knxAddresses.find(address => address.variable === variable)
            if(address){
              this.accessoryData.details.knxAddresses.splice(this.accessoryData.details.knxAddresses.indexOf(address), 1)
            }

            if(variable === "actionOn"){
              this.cachedActionOnAddress = address;
              address = this.accessoryData.details.knxAddresses.find(address => address.variable === "actionOff")
              this.cachedActionOffAddress = address;
              this.accessoryData.details.knxAddresses.splice(this.accessoryData.details.knxAddresses.indexOf(address), 1)
            }
            else if(variable === "actionOff"){
              this.cachedActionOffAddress = address;
              address = this.accessoryData.details.knxAddresses.find(address => address.variable === "actionOn")
              this.cachedActionOnAddress = address;
              this.accessoryData.details.knxAddresses.splice(this.accessoryData.details.knxAddresses.indexOf(address), 1)
            }
            else if(variable === "temperature"){
                this.cachedTemperatureAddress = address;
            }
            else if(variable === "heatingIndication"){
                this.cachedHeatingIndicationAddress = address;
            }
            else if(variable === "coolingIndication"){
                this.cachedCoolingIndicationAddress = address;
            }
            else if(variable === "CO2forTemperature"){
                this.cachedCO2Address = address;
            }
            else if(variable === "humidityForTemperature"){
                this.cachedHumidityAddress = address;
            }
            else if(variable === "airQualityForTemperature"){
                this.cachedAirQualityAddress = address;
            }
            // else if(variable === "CO2"){
            //     this.cachedCO2Address = address;
            // }
            // else if(variable === "humidity"){
            //     this.cachedHumidityAddress = address;
            // }


          }
      },

      updateSetPoints: function(){
        let setPoints = []
        let min = parseInt(this.minSetPoint)
        let max = parseInt(this.maxSetPoint)
        let step = parseFloat(this.setPointStep)

        if (max <= min || step <= 0)
          return

        console.log("min, max, step", min, max, step)

        for (var i = min; i <= max; i += step) {
          i = parseFloat(i.toFixed(2))
          setPoints.push(i)
        }

        console.log("updateSetPoints", setPoints)

        this.accessoryData.targetSetPoints = setPoints
      },

      knxAddress: function(variable){
        var address = this.accessoryData.details.knxAddresses.find(address => address.variable === variable)
        if (!address) {

          if(variable === "actionOn" && this.cachedActionOnAddress != null){
            this.accessoryData.details.knxAddresses.push(this.cachedActionOnAddress)
            return this.cachedActionOnAddress
          }
          else if(variable === "actionOff" && this.cachedActionOffAddress != null){
            this.accessoryData.details.knxAddresses.push(this.cachedActionOffAddress)
            return this.cachedActionOffAddress            
          }
          else if(variable === "temperature" && this.cachedTemperatureAddress != null){
            this.accessoryData.details.knxAddresses.push(this.cachedTemperatureAddress)
            return this.cachedTemperatureAddress                    
          }
          else if(variable === "heatingIndication" && this.cachedHeatingIndicationAddress != null){
            this.accessoryData.details.knxAddresses.push(this.cachedHeatingIndicationAddress)
            return this.cachedHeatingIndicationAddress                    
          }
          else if(variable === "coolingIndication" && this.cachedCoolingIndicationAddress != null){
            this.accessoryData.details.knxAddresses.push(this.cachedCoolingIndicationAddress)
            return this.cachedCoolingIndicationAddress                    
          }
          else if(variable === "CO2forTemperature" && this.cachedCO2ForTemperatureAddress != null){
            this.accessoryData.details.knxAddresses.push(this.cachedCO2ForTemperatureAddress)
            return this.cachedCO2ForTemperatureAddress                    
          }
          else if(variable === "humidityForTemperature" && this.cachedHumidityForTemperatureAddress != null){
            this.accessoryData.details.knxAddresses.push(this.cachedHumidityForTemperatureAddress)
            return this.cachedHumidityForTemperatureAddress                    
          }
          else if(variable === "airQualityForTemperature" && this.cachedAirQualityForTemperatureAddress != null){
            this.accessoryData.details.knxAddresses.push(this.cachedAirQualityForTemperatureAddress)
            return this.cachedAirQualityForTemperatureAddress                    
          }
          // else if(variable === "CO2" && this.cachedCO2Address != null){
          //   this.accessoryData.details.knxAddresses.push(this.cachedCO2Address)
          //   return this.cachedCO2Address                    
          // }
          // else if(variable === "humidity" && this.cachedHumidityAddress != null){
          //   this.accessoryData.details.knxAddresses.push(this.cachedHumidityAddress)
          //   return this.cachedHumidityAddress                    
          // }
          

          var dpt = "6.001"
          if (this.setPointType == "shiftOffsetC")
            dpt = "9.001"
          if (variable ==="heatingIndication")
            dpt = "1.001"
          if (variable ==="coolingIndication")
            dpt = "1.001"
          if (variable === "CO2" || variable ==="CO2forTemperature")
            dpt = "9.001"
          if (variable === "humidity" || variable ==="humidityForTemperature")
            dpt = "9.001"
          if (variable === "airQuality" || variable ==="airQualityForTemperature")
            dpt = "5.004"

          address = {
            variable: variable,
            dpt: dpt,
            writeAddress: "",
            readAddress: ""
          }
          this.accessoryData.details.knxAddresses.push(address)
        }


        return address
      },



      titleTab: function (type) {
        return components.find(component => component.type === type).title
      },



      titleTabContent: function(componentType, modeType){
        return this.getModeByType(componentType, modeType).title
      },


      iconMode: function(componentType, modeType){
        return this.getModeByType(componentType, modeType).icon
      },

      onChangeModeName: function(val, componentType, modeType){
        console.log("onChangeModeName", val, componentType, modeType)
        console.log("components", components)

        let mode = this.accessoryData.details.components.find(comp => comp.type === componentType).modes.find(mode => mode.type === modeType)
        mode.title = val
      },


      onSelectIcon: function(compType, modeType, icon){
        let mode = this.accessoryData.details.components.find(comp => comp.type === compType).modes.find(mode => mode.type === modeType)
        mode.icon = icon.value

        this.visibleIcons[modeType] = false
      },

      onSelectImage: function(compType, modeType, icon){
        console.log("onSelectImage", compType, modeType, icon)
        let mode = this.accessoryData.details.components.find(comp => comp.type === compType).modes.find(mode => mode.type === modeType)
        mode.icon = icon
      },


      onCheckedMode: function(compType, modeType){
        console.log("onCheckedMode", this.checkedList)

        let comp = components.find(comp => comp.type === compType)
        let modes = comp.modes.filter(mode => {
          return this.checkedList[mode.type]
        })

        let component = this.accessoryData.details.components.find(comp => comp.type === compType)
        if (component) {
          let compModes = JSON.parse(JSON.stringify(component.modes))//[...component.modes]
          console.log("onCheckedMode, old modes1", compModes)

          component.modes = JSON.parse(JSON.stringify(modes))//[...modes]
          console.log("onCheckedMode, old modes2", compModes)
          console.log("onCheckedMode, new modes1", component.modes)

          component.modes = component.modes.map(mode => {
            let old = compModes.find(m => m.type === mode.type)
            if (old)
              return {...mode, title: old.title, icon: old.icon}
            else
              return mode
          })

          console.log("onCheckedMode, new modes2", component.modes)

        }
        let knxAddresses = this.accessoryData.details.knxAddresses
        let modeAddresses = []
        knxAddresses.forEach(address => {
          components.forEach(comp => {
            let mode = comp.modes.find(mode => mode.type === address.variable)
            if (mode) {
              if (!modeAddresses.find(addr => addr.variable === mode.type))
                modeAddresses.push({...address})
            }
          })
        })

        let checkedAddresses = modeAddresses.filter(address => this.checkedList[address.variable] )
        let tempAddresses = knxAddresses.filter(address => {
          let val = modeAddresses.find(addr => addr.variable === address.variable)
          if (val)
            return false
          return true
        })

        tempAddresses.push.apply(tempAddresses, checkedAddresses)

        if (this.checkedList[modeType]) {
          tempAddresses.push({
            variable: modeType,
            dpt: "1.001",
            writeAddress: "",
            readAddress: "",
            writeValue: "",
            activeValue: ""
          })
        }
        this.accessoryData.details.knxAddresses = tempAddresses
        console.log("onCheckedMode, all addresses", this.accessoryData.details.knxAddresses)
      },

      applyAll(compType, knxAddress){
        let component = this.accessoryData.details.components.find(comp => comp.type === compType)
        let modes = component.modes.map(mode => mode.type)

        let knxAddresses = this.accessoryData.details.knxAddresses
        this.accessoryData.details.knxAddresses = knxAddresses.map(address => {
          if (modes.includes(address.variable)) {
            return {...knxAddress, variable: address.variable, writeValue: address.writeValue, activeValue: address.activeValue}
          } else {
            return address
          }
        })
      },

      getModeByType(componentType, modeType) {
        const mode = this.accessoryData.details.components.find(comp => comp.type === componentType).modes.find(mode => mode.type === modeType)
        if(!mode) {
          return components.find(comp => comp.type === componentType).modes.find(mode => mode.type === modeType)
        }
        return mode
      },

      removeTab(name){
        console.log("tab", name)

        let tabs = this.accessoryData.details.components
        let activeName = this.tabValue

        if (activeName === name) {
          this.accessoryData.details.components.forEach((tab, index) => {
            if (tab.type === name) {
              let nexTab = tabs[index + 1] || this.accessoryData.details.components[index - 1]
              if (nexTab) {
                activeName = nexTab.type
              }
            }
          })
        }

        let removedComp = tabs.find(comp => comp.type == name)
        this.accessoryData.details.knxAddresses = this.accessoryData.details.knxAddresses.filter(address => {
          let removedMode = removedComp.modes.find(mode => mode.type === address.variable)
          return removedMode ? false : true
        })


        this.tabValue = activeName
        this.accessoryData.details.components = tabs.filter(tab => tab.type !== name)
      },

      addTab(component){
        let tab = {...component}

        tab.modes.forEach(mode => {
          this.checkedList[mode.type] = true

          let defaultValues = this.getDefaultValues(component.type, mode.type)

          console.log("addTab", component, mode, defaultValues)

          var address = this.accessoryData.details.knxAddresses.find(address => address.variable === mode.type)
          // if(!address && mode.type == 'humidity'){
          //   address = {
          //     variable: mode.type,
          //     dpt: "7.001",
          //     readAddress: ""
          //   }
          // }
          // else if(!address && mode.type == 'CO2'){
          //   address = {
          //     variable: mode.type,
          //     dpt: "5.001",
          //     readAddress: ""
          //   }
          // }
          if(!address) {
            address = {
              variable: mode.type,
              dpt: defaultValues.dpt,
              writeAddress: "",
              readAddress: "",
              writeValue: defaultValues.writeValue,
              activeValue: defaultValues.activeValue
            }

            this.accessoryData.details.knxAddresses.push(address)
          }
        })

        this.accessoryData.details.components.push(tab)


        this.tabValue = tab.type
        this.tabIndex++

        this.visibleAddTab = false
      },

      getDefaultValues(component, mode){
        let dpt = ""
        let value = ""

        if (component === "modeOpp") {
          dpt = "5.004"
          if (mode === "modeOppAuto") value = "0"
          if (mode === "modeOppHeat") value = "1"
          if (mode === "modeOppCool") value = "3"
          if (mode === "modeOppFan") value = "9"
          if (mode === "modeOppDry") value = "14"
        } else if (component === "modeControl") {
          dpt = "5.004"
          if (mode === "modeControlAuto") value = "0"
          if (mode === "modeControlComfort") value = "1"
          if (mode === "modeControlStandby") value = "2"
          if (mode === "modeControlNight") value = "3"
          if (mode === "modeControlProtection") value = "4"
        } else if (component === "modeFan") {

          if (mode === "modeFanAuto") {
            dpt = "1.001"
            value = "1"
          } else {
            dpt = "5.001"
            if (mode === "modeFanLowest") value = "20"
            if (mode === "modeFanLower") value = "40"
            if (mode === "modeFanMiddle") value = "60"
            if (mode === "modeFanHigher") value = "80"
            if (mode === "modeFanHighest") value = "100"
          }
        } else if (component === "modeUpDown") {
          dpt = "5.001"
          if (mode === "modeUpDownAuto") value = "0"
          if (mode === "modeUpDownPosition1") value = "1"
          if (mode === "modeUpDownPosition2") value = "2"
          if (mode === "modeUpDownPosition3") value = "3"
          if (mode === "modeUpDownPosition4") value = "4"
          if (mode === "modeUpDownPosition5") value = "5"
          if (mode === "modeUpDownSwing") value = "6"
        } else if (component === "modeLeftRight") {
          dpt = "5.001"
          if (mode === "modeLeftRightAuto") value = "0"
          if (mode === "modeLeftRightPosition1") value = "1"
          if (mode === "modeLeftRightPosition2") value = "2"
          if (mode === "modeLeftRightPosition3") value = "3"
          if (mode === "modeLeftRightPosition4") value = "4"
          if (mode === "modeLeftRightPosition5") value = "5"
          if (mode === "modeLeftRightSwing") value = "6"
        } else {
          dpt = "1.001"
          value = ""
        }

        return {
          dpt: dpt,
          writeValue: value,
          activeValue: value
        }
      },

      // validateAddress(){
      //   const failAddresses = this.accessoryData.details.knxAddresses.filter(address => {
      //     // return (this.showReadAddress(address.variable) && !this.validateKnxGroupAddress(address.readAddress))
      //     //   || (this.showWriteAddress(address.variable) && !this.validateKnxGroupAddress(address.writeAddress))
      //      if(address.hasOwnProperty("readAddress") && address.readAddress != ''){
      //       return !this.validateKnxGroupAddress(address.readAddress)
      //      }
      //      if(address.hasOwnProperty("writeAddress") && address.writeAddress != '')
      //      {
      //       return !this.validateKnxGroupAddress(address.writeAddress)
      //      }
      //   })

      //   if (failAddresses.length > 0) {
      //     this.$message({ type: "error", message: this.$t('accessory.form-rules.knx-address-invalid')})
      //     return false
      //   } else {
      //     return true
      //   }
      // },

      validate(){
        console.log('DATA', this.accessoryData)
        const result = this.accessoryData.details.knxAddresses.some(address => {
          if(address.hasOwnProperty("readAddress")){return address.readAddress == ''}
          if(address.hasOwnProperty("writeAddress")){return address.writeAddress == ''}
          // return !this.errorFields.hasOwnProperty(address.variable) || this.errorFields[address.variable] == null
        })

        console.log('Ac Data',this.accessoryData.details.knxAddresses)
        console.log('Error Fields',this.errorFields)

        if (result) {
          this.$message({ type: "error", message: this.$t('accessory.form-rules.fill-all-empty-fields')})
          return false
        }

        const failAddresses = this.accessoryData.details.knxAddresses.filter(address => {
          // return (this.showReadAddress(address.variable) && !this.validateKnxGroupAddress(address.readAddress))
          //   || (this.showWriteAddress(address.variable) && !this.validateKnxGroupAddress(address.writeAddress))
           if(address.hasOwnProperty("readAddress") && address.readAddress != ''){
            return !this.validateKnxGroupAddress(address.readAddress)
           }
           if(address.hasOwnProperty("writeAddress") && address.writeAddress != '')
           {
            return !this.validateKnxGroupAddress(address.writeAddress)
           }
        })

        if (failAddresses.length > 0) {
          this.$message({ type: "error", message: this.$t('accessory.form-rules.knx-address-invalid')})
          return false
        } else {
          return true
        }

        // var msg = []
        // for (const [key, value] of Object.entries(this.errorFields)) {
        //   if (value)
        //     msg.push(value)
        // }

        // if (msg.length > 0) {
        //   const msgStr = msg.join("<br>")
        //   this.$message({ type: "error", message: msgStr, dangerouslyUseHTMLString: true})
        //   return false
        // }

        // return true


        /*var message = ""
        this.accessoryData.details.knxAddresses.forEach(address => {
          var msg = []
          if ( (this.showReadAddress(address.variable) && !this.validateKnxGroupAddress(address.readAddress))
            || (this.showWriteAddress(address.variable) && !this.validateKnxGroupAddress(address.writeAddress)) ) {
            msg.push(this.$t('accessory.form-rules.knx-address-invalid'))
          }
          if (!address.dpt) {
            msg.push(this.$t('accessory.form-rules.dpt-empty'))
          }
          if (!this.validateKnxValue(address.dpt, address.value)) {
            msg.push(this.$t('accessory.form-rules.knx-value-invalid'))
          }

          if (msg.length > 0) {
            const msgStr = msg.join(", ")
            message += "[" + address.variable + "] " + msgStr + "<br>"
          }
        })

        if (message) {
          this.$message({ type: "error", message: message, dangerouslyUseHTMLString: true})
          return false
        } else {
          return true
        }*/
      },

      onChangeKnxField(variable, msg){
        console.log("onChangeKnxField", variable, msg)
        this.errorFields[variable] = msg
      }
    },




    created(){
      if (!this.accessoryData.details.knxAddresses) {
        this.accessoryData.details = {

          knxAddresses: [
            {
              variable: "setPoint",
              dpt: "9.001",
              writeAddress: "",
              readAddress: ""
            },
            {
              variable: "actionOn",
              dpt: "1.001",
              writeAddress: "",
              readAddress: "",
              writeValue: "",
              activeValue: ""
            },
            {
              variable: "actionOff",
              dpt: "1.001",
              writeAddress: "",
              readAddress: "",
              writeValue: "",
              activeValue: ""
            },
            {
              variable: "temperature",
              readAddress: ""
            }
          ],
          components: [],

          setPointType: "direct" // or shift

        }
      } else {
        this.accessoryData.details.knxAddresses.forEach(address => {
          console.log("created, address", address)
        })

        this.onStateEnabled = this.accessoryData.details.knxAddresses.find(address => address.variable === "actionOn") != undefined
        this.offStateEnabled = this.accessoryData.details.knxAddresses.find(address => address.variable === "actionOff") != undefined
        this.temperatureEnabled = this.accessoryData.details.knxAddresses.find(address => address.variable === "temperature") != undefined
        this.heatingIndicationEnabled = this.accessoryData.details.knxAddresses.find(address => address.variable === "heatingIndication") != undefined
        this.coolingIndicationEnabled = this.accessoryData.details.knxAddresses.find(address => address.variable === "coolingIndication") != undefined
        this.co2Enabled = this.accessoryData.details.knxAddresses.find(address => address.variable === "CO2forTemperature") != undefined
        this.humidityEnabled = this.accessoryData.details.knxAddresses.find(address => address.variable === "humidityForTemperature") != undefined
        this.airQualityEnabled = this.accessoryData.details.knxAddresses.find(address => address.variable === "airQualityForTemperature") != undefined
      }


      if (this.accessoryData.details.components) {
        this.accessoryData.details.components.forEach(comp => {
          comp.modes.forEach(mode => {
            console.log("created, mode", mode.type, mode.icon)
            this.checkedList[mode.type] = true
          })
        })
      }


      if (this.accessoryData.targetSetPoints) {
        var length = this.accessoryData.targetSetPoints.length
        var min = parseFloat(this.accessoryData.targetSetPoints[0])
        var max = parseFloat(this.accessoryData.targetSetPoints[length - 1])
        var step = length > 1 ? ( parseFloat(this.accessoryData.targetSetPoints[1]) - parseFloat(this.accessoryData.targetSetPoints[0]) ) : 1

        this.minSetPoint = parseInt(min)
        this.maxSetPoint = parseInt(max)
        this.setPointStep = parseFloat(step.toFixed(2))

        console.log("setpoitns", this.minSetPoint, this.maxSetPoint, this.setPointStep)

      } else {
        this.updateSetPoints()
      }

      this.setPointType = this.accessoryData.details.setPointType
      if (!this.setPointType)
        this.setPointType = "direct"
    }
  }
</script>

<style scoped>

  .line {
    height: 1px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #eeeeee;
  }

  .icon-contact {
    filter: brightness(0.45);
    width: 24px;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
  }

  .selectable-icon {
    filter: brightness(0.45);
    width: 24px;
    height: 24px;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    border: 1px solid #eeeeee;
  }

  .selectable-icon:hover {
    filter: brightness(0.65);
  }

  .mode-container {
    border: 1px solid #eeeeee;
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;
  }

  .item-container {
    margin-top: 20px;
    padding: 15px
  }

  .number-input {
    width: 100px;
    margin-left: 15px;
    margin-right: 15px;
  }

</style>