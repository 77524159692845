var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        {
          staticStyle: { width: "400px" },
          attrs: {
            label: _vm.$t("accessory.indoor-id"),
            prop: "details.indoorId"
          }
        },
        [
          _c("el-input", {
            attrs: {
              type: "text",
              "auto-complete": "on",
              placeholder: _vm.$t("accessory.type-indoor-id")
            },
            model: {
              value: _vm.accessoryData.details.indoorId,
              callback: function($$v) {
                _vm.$set(_vm.accessoryData.details, "indoorId", $$v)
              },
              expression: "accessoryData.details.indoorId"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }