import {
  ACCESSORY_CATEGORY_AIR_CONDITIONER,
  categoryNames,
} from "./accessory-utils";

export const coolmasterCategoryList = [ACCESSORY_CATEGORY_AIR_CONDITIONER];

export function coolmasterCategories() {
  return categoryNames.filter((category) =>
    coolmasterCategoryList.includes(category.value)
  );
}
