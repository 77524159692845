<template>
  <div>

    <el-form-item prop="details.id" :label="$t('accessory.output-number')" :rules="[{required: true, message: $t('accessory.form-rules.select-output')}]">
      <el-select v-model="accessoryData.details.id" :placeholder="$t('accessory.select-output')">
        <el-option v-for="(output, index) in outputs" :label="getItemTitle(index)" :value="output.value" :key="output.value">
          <span> {{ getItemTitle(index) }}</span>
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item v-if="false" prop="details.switchControl" :label="$t('accessory.key-control')" style="width: 400px">
      <el-switch v-model="accessoryData.details.switchControl"></el-switch>
    </el-form-item>

    <el-form-item prop="onValue" :label="$t('accessory.normally')" style="width: 400px">
      <el-switch v-model="onValue"></el-switch>
    </el-form-item>

    <el-row>
      <el-col :span="10">

        <el-form-item prop="pulse" :label="$t('accessory.pulse')" style="width: 400px">
          <el-switch v-model="pulse"></el-switch>
        </el-form-item>

      </el-col>

      <el-col :span="10" v-show="pulse">

        <el-form-item :label="$t('accessory.duration')" prop="pulseDuration">
          <el-input-number v-model="pulseDuration" :min="10" :step="10">
          </el-input-number>
          <span style="margin-left: 10px;">ms.</span>
        </el-form-item>

      </el-col>
    </el-row>

  </div>
</template>

<script>
  import {gpioMixin} from "./gpioMixin";

  export default {
    name: "GpioSwitchInputs",
    data: function(){
      return {
        onValue: 'onValue' in this.accessoryData ? this.accessoryData.onValue : true,
        pulse: 'pulse' in this.accessoryData ? this.accessoryData.pulse : false,
        pulseDuration: 'pulseDuration' in this.accessoryData ? this.accessoryData.pulseDuration : 50,
      }
    },

    mixins: [gpioMixin],

    watch: {
      onValue: function(value){
        this.accessoryData.onValue = value
      },

      pulse: function(value){
        this.accessoryData.pulse = value
      },

      pulseDuration: function(value){
        this.accessoryData.pulseDuration = value
      },
    },

    props: {
      accessoryData: {}
    },

    methods: {
      getItemTitle: function (index) {
        return this.$t('accessory.output') + ' ' + (index + 1)
      }
    },

    created(){
      console.log(this.accessoryData)

      if (!this.accessoryData.hasOwnProperty('onValue'))
        this.accessoryData.onValue = this.onValue

      if (!this.accessoryData.hasOwnProperty('pulse'))
        this.accessoryData.pulse = this.pulse

      if (!this.accessoryData.hasOwnProperty('pulseDuration'))
        this.accessoryData.pulseDuration = this.pulseDuration

      if (!this.accessoryData.details.hasOwnProperty("direction"))
        this.accessoryData.details.direction = "out"

    }
  }
</script>

<style scoped>

</style>