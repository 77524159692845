var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "details.io",
            label: _vm.$t("accessory.output-number"),
            rules: [
              {
                required: true,
                message: _vm.$t("accessory.form-rules.select-output")
              }
            ]
          }
        },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: _vm.$t("accessory.select-output") },
              model: {
                value: _vm.accessoryData.details.io,
                callback: function($$v) {
                  _vm.$set(_vm.accessoryData.details, "io", $$v)
                },
                expression: "accessoryData.details.io"
              }
            },
            _vm._l(_vm.outputs, function(index) {
              return _c("el-option", {
                key: index + "",
                attrs: {
                  label: "Q" + (index + 1) + "-" + "Q" + (index + 2),
                  value: 8192 + index
                }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }