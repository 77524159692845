<template>

  <div :style="{marginLeft: editMode ? (210 + 'px') : (0 + 'px')}">
    <div class="form-item" v-for="(address, index) in accessoryData.details.knxAddresses" :key="index">
      <el-form-item prop="address" label-width="0" style="width: 100%">

        <span>{{ title(address.variable) }}</span>
        <div class="line"></div>

        <el-row :gutter="innerSpace">
          <el-col v-if="showWriteAddress(address.variable)" :span="3">{{ $t('accessory.write') }}</el-col>
          <el-col v-if="showWriteAddress(address.variable)" :span="6">
            <el-input type="text" v-model="address.writeAddress" placeholder=".../.../..." v-mask="groupAddressMask">
            </el-input>
          </el-col>
          <el-col v-if="showReadAddress(address.variable)" :span="3">{{ $t('accessory.read') }}</el-col>
          <el-col v-if="showReadAddress(address.variable)" :span="6">
            <el-input type="text" v-model="address.readAddress" placeholder=".../.../..." v-mask="groupAddressMask">
            </el-input>
          </el-col>
        </el-row>

      </el-form-item>
    </div>
  </div>

</template>

<script>
  import {knxMixin} from "./knxMixin";

  export default {
    name: "KnxDimmerInputs",

    mixins: [knxMixin],

    data: function(){
      return {
        switchWrite: "",
        switchRead: "",
        dimmingWrite: "",
        dimmingRead: ""
      }
    },

    props: {
      accessoryData: {},
      editMode: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      validate(){
        const failAddresses = this.accessoryData.details.knxAddresses.filter(address => {
          return (this.showReadAddress(address.variable) && !this.validateKnxGroupAddress(address.readAddress))
            || (this.showWriteAddress(address.variable) && !this.validateKnxGroupAddress(address.writeAddress))
        })

        if (failAddresses.length > 0) {
          this.$message({ type: "error", message: this.$t('accessory.form-rules.knx-address-invalid')})
          return false
        } else {
          return true
        }
      }
    },

    created(){
      console.log(this.accessoryData.details.knxAddresses)
      if (!this.accessoryData.details.knxAddresses) {
        this.accessoryData.details = {
          knxAddresses: [
            {
              variable: "onOffState",
              writeAddress: "",
              readAddress: ""
            },
            {
              variable: "deviceLevel",
              writeAddress: "",
              readAddress: ""
            }
          ]
        }
      }

    }
  }
</script>

<style scoped>
  .line {
    height: 1px;
    margin: 0px 0px 20px 0px;
    background-color: #eaeaea;
  }
  .form-item {
    margin: 10px;
    padding: 10px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    color: #606266;
  }
  .form-item span {
    font-weight: bold;
  }
</style>