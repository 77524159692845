var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "details.io",
            label: _vm.$t("accessory.input-number"),
            rules: [
              {
                required: true,
                message: _vm.$t("accessory.form-rules.select-input")
              }
            ]
          }
        },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: _vm.$t("accessory.select-input") },
              model: {
                value: _vm.accessoryData.details.io,
                callback: function($$v) {
                  _vm.$set(_vm.accessoryData.details, "io", $$v)
                },
                expression: "accessoryData.details.io"
              }
            },
            _vm._l(_vm.inputs, function(index) {
              return _c(
                "el-option",
                {
                  key: index + "",
                  attrs: { label: "I" + (index + 1), value: index }
                },
                [
                  _c("span", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v(_vm._s("I" + (index + 1)))
                  ]),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          "(" +
                            _vm.$t("accessory.input") +
                            " " +
                            (index + 1) +
                            ")"
                        )
                    )
                  ])
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticStyle: { width: "400px" },
          attrs: { prop: "trippedValue", label: _vm.$t("accessory.normally") }
        },
        [
          _c("el-switch", {
            attrs: { "active-value": true, "inactive-value": false },
            model: {
              value: _vm.trippedValue,
              callback: function($$v) {
                _vm.trippedValue = $$v
              },
              expression: "trippedValue"
            }
          })
        ],
        1
      ),
      !_vm.isSuddenSensor()
        ? _c(
            "el-form-item",
            {
              staticStyle: { width: "400px" },
              attrs: {
                prop: "entryDelay",
                label: _vm.$t("accessory.entry-delay"),
                rules: [
                  {
                    required: true,
                    message: _vm.$t("accessory.form-rules.enter-entry-delay")
                  }
                ]
              }
            },
            [
              _c("el-input", {
                attrs: {
                  type: "number",
                  placeholder: _vm.$t("accessory.enter-entry-delay")
                },
                model: {
                  value: _vm.accessoryData.entryDelay,
                  callback: function($$v) {
                    _vm.$set(_vm.accessoryData, "entryDelay", $$v)
                  },
                  expression: "accessoryData.entryDelay"
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.isSuddenSensor()
        ? _c(
            "el-form-item",
            {
              staticStyle: { width: "400px" },
              attrs: {
                prop: "armStateInAwayMode",
                label: _vm.$t("accessory.away")
              }
            },
            [
              _c("el-switch", {
                on: { change: _vm.changedSwitch },
                model: {
                  value: _vm.armStateInAwayMode,
                  callback: function($$v) {
                    _vm.armStateInAwayMode = $$v
                  },
                  expression: "armStateInAwayMode"
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.isSuddenSensor()
        ? _c(
            "el-form-item",
            {
              staticStyle: { width: "400px" },
              attrs: {
                prop: "armStateInStayMode",
                label: _vm.$t("accessory.stay")
              }
            },
            [
              _c("el-switch", {
                model: {
                  value: _vm.armStateInStayMode,
                  callback: function($$v) {
                    _vm.armStateInStayMode = $$v
                  },
                  expression: "armStateInStayMode"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }