export const gpioMixin = {
  data: function () {
    return {
      inputs: [
        { title: this.$t('accessory.input') + ' ' + 1, value: "118" },
        { title: this.$t('accessory.input') + ' ' + 2, value: "120" },
        { title: this.$t('accessory.input') + ' ' + 3, value: "121" },
        { title: this.$t('accessory.input') + ' ' + 4, value: "122" },
        { title: this.$t('accessory.input') + ' ' + 5, value: "51" },
      ],

      outputs: [
        { title: "2 - 102", value: "2" },
        { title: "50 - 107", value: "50" },
        { title: "72 - 109", value: "72" },
        { title: "1 - 106", value: "1" },
      ]
    }
  }
}