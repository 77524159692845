<template>
  <div>

    <el-form-item prop="details.io" :label="$t('accessory.output-number')" :rules="[{required: true, message: $t('accessory.form-rules.select-output')}]">
      <el-select v-model="accessoryData.details.io" :placeholder="$t('accessory.select-output')">
        <el-option v-for="index in outputs" :label="'Q' + (index + 1)" :value="(8192 + index)" :key="index + ''">
          <span style="font-weight: bold">{{ 'Q' + (index + 1) }}</span>
          <span> {{ '(' + $t('accessory.output') + ' ' + (index + 1) + ')'}}</span>
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item prop="details.switchControl" :label="$t('accessory.key-control')" style="width: 400px">
      <el-switch v-model="accessoryData.details.switchControl"></el-switch>
    </el-form-item>

    <el-form-item prop="onValue" :label="$t('accessory.normally')" style="width: 400px">
      <el-switch v-model="onValue"></el-switch>
    </el-form-item>

    <el-row>
      <el-col :span="10">

        <el-form-item prop="pulse" :label="$t('accessory.pulse')" style="width: 400px">
          <el-switch v-model="pulse"></el-switch>
        </el-form-item>

      </el-col>

      <el-col :span="10" v-show="pulse">

        <el-form-item :label="$t('accessory.duration')" prop="pulseDuration">
          <el-input-number v-model="pulseDuration" :min="10" :step="10">
          </el-input-number>
          <span style="margin-left: 10px;">ms.</span>
        </el-form-item>

      </el-col>
    </el-row>

  </div>
</template>

<script>
  import {outputs} from "@/utils/logo";

  export default {
    name: "LogoSwitchInputs",
    data: function(){
      return {
        onValue: 'onValue' in this.accessoryData ? this.accessoryData.onValue : true,
        pulse: 'pulse' in this.accessoryData ? this.accessoryData.pulse : false,
        pulseDuration: 'pulseDuration' in this.accessoryData ? this.accessoryData.pulseDuration : 50,
      }
    },

    computed: {
      outputs(){
        return [...Array(20).keys()]
      }
    },

    watch: {
      onValue: function(value){
        this.accessoryData.onValue = value
      },

      pulse: function(value){
        this.accessoryData.pulse = value
      },

      pulseDuration: function(value){
        this.accessoryData.pulseDuration = value
      },
    },

    props: {
      accessoryData: {}
    },

    created(){
      console.log(this.accessoryData)

      if (!this.accessoryData.hasOwnProperty('onValue'))
        this.accessoryData.onValue = this.onValue

      if (!this.accessoryData.hasOwnProperty('pulse'))
        this.accessoryData.pulse = this.pulse

      if (!this.accessoryData.hasOwnProperty('pulseDuration'))
        this.accessoryData.pulseDuration = this.pulseDuration

    }
  }
</script>

<style scoped>

</style>