<template>
    <div>
        <el-form-item style="width: 410px" prop="details.device.unitId" :label="$t('accessory.modbus.device-address')">
            <el-input min="0" type="number" v-model="accessoryData.details.device.unitId"/>
        </el-form-item>

        <el-form-item prop="details.device.actions.setOn.addressType" :label="$t('accessory.modbus.address-type')">
            <el-select v-model="addressType" @change="onSelectSetOnAddressType">
                <el-option v-for="addressType in switchAddressTypeNames" :label="addressType.title" :value="addressType.value" :key="addressType.value">
                </el-option>
            </el-select>
        </el-form-item>
        
        <el-form-item style="width: 410px" prop="details.device.actions.setOn.address" :label="$t('accessory.modbus.register-address')">
            <el-input min="0" type="number" @change="onRegisterAddressChanged" v-model="accessoryData.details.device.actions.setOn.address"/>
        </el-form-item>   

        <el-form-item v-show="addressType == ADDRESS_TYPE_HOLDING_REGISTER" style="width: 410px" prop="details.device.actions.setOn.onValue" :label="$t('accessory.modbus.on-value')">
            <el-input min="0" type="number" @change="onOnValueChanged" v-model="accessoryData.details.device.actions.setOn.onValue"/>
        </el-form-item>

        <el-form-item v-show="addressType == ADDRESS_TYPE_HOLDING_REGISTER" style="width: 410px" prop="details.device.actions.setOn.offValue" :label="$t('accessory.modbus.off-value')">
            <el-input min="0" type="number" @change="onOffValueChanged" v-model="accessoryData.details.device.actions.setOn.offValue"/>
        </el-form-item>

        <el-form-item v-show="addressType == ADDRESS_TYPE_COIL" style="width: 410px" prop="coilOnValue" :label="$t('accessory.modbus.on-value')">
            <el-input min="0" type="number" @change="onOnValueChanged" v-model="coilOnValue"/>
        </el-form-item>        
    </div>
</template>

<script>
  import {ADDRESS_TYPE_COIL, ADDRESS_TYPE_HOLDING_REGISTER, switchAddressTypeList} from "@/utils/modbus";

    export default {
        name: "ModbusSwitchInputs",
        data: function(){
        return {
            ADDRESS_TYPE_HOLDING_REGISTER,
            ADDRESS_TYPE_COIL,            
            coilOnValue: 1,
            addressType: 0,
            onValue: 'onValue' in this.accessoryData ? this.accessoryData.onValue : true,
            pulse: 'pulse' in this.accessoryData ? this.accessoryData.pulse : false,
            pulseDuration: 'pulseDuration' in this.accessoryData ? this.accessoryData.pulseDuration : 50,
            }
        },

        props: {
            accessoryData: {}
        },    

        computed: {
            switchAddressTypeNames(){
                return switchAddressTypeList
            }
        },   

        methods:{
        
            onRegisterAddressChanged(value){
                this.accessoryData.details.device.statuses.setOn.address = value
            },

            onOnValueChanged(value){
                if(this.accessoryData.details.device.statuses.setOn.addressType == ADDRESS_TYPE_HOLDING_REGISTER)
                    this.accessoryData.details.device.statuses.setOn.onValue = value
                else if(this.accessoryData.details.device.actions.setOn.addressType == ADDRESS_TYPE_COIL){
                    if(value < 0)
                        value = 0
                    else if(value > 1)
                        value = 1

                    let onValue = value
                    let offValue = onValue == 1 ? 0 : 1
                    
                    this.accessoryData.details.device.actions.setOn.onValue = onValue
                    this.accessoryData.details.device.actions.setOn.offValue = offValue;

                    this.accessoryData.details.device.statuses.setOn.onValue = onValue
                    this.accessoryData.details.device.statuses.setOn.offValue = offValue;     
                    
                    this.coilOnValue = onValue                    
                }
            },

            onOffValueChanged(value){
                if(this.accessoryData.details.device.statuses.setOn.addressType == ADDRESS_TYPE_HOLDING_REGISTER)
                    this.accessoryData.details.device.statuses.setOn.offValue = value
            },

            onSelectSetOnAddressType(value){
                this.accessoryData.details.device.actions.setOn.addressType = value
                this.accessoryData.details.device.statuses.setOn.addressType = value

                if(value == ADDRESS_TYPE_COIL){
                    this.accessoryData.details.device.actions.setOn.onValue = 1
                    this.accessoryData.details.device.actions.setOn.offValue = 0

                    this.accessoryData.details.device.statuses.setOn.onValue = 1
                    this.accessoryData.details.device.statuses.setOn.offValue = 0
                }
                else{
                    this.accessoryData.details.device.actions.setOn.onValue = 1
                    this.accessoryData.details.device.actions.setOn.offValue = 0

                    this.accessoryData.details.device.statuses.setOn.onValue = 1
                    this.accessoryData.details.device.statuses.setOn.offValue = 0
                }
            },

        },
        
        created(){

            if(!this.accessoryData.details.device){
                this.accessoryData.details.device = {}

                this.accessoryData.details.device.unitId = 1

                this.accessoryData.details.device.actions = {}
                this.accessoryData.details.device.actions.setOn = {}

                this.accessoryData.details.device.actions.setOn.address = 1
                this.accessoryData.details.device.actions.setOn.addressType = 0
                this.accessoryData.details.device.actions.setOn.onValue = 1
                this.accessoryData.details.device.actions.setOn.offValue = 0

                this.accessoryData.details.device.statuses = {}
                this.accessoryData.details.device.statuses.setOn = {}

                this.accessoryData.details.device.statuses.setOn.address = 1
                this.accessoryData.details.device.statuses.setOn.addressType = 0
                this.accessoryData.details.device.statuses.setOn.onValue = 1
                this.accessoryData.details.device.statuses.setOn.offValue = 0

                this.accessoryData.details.device.actions.setOn.operationName = "HHModbusOnOff"
                this.accessoryData.details.device.statuses.setOn.operationName = "HHModbusOnOff"

                if (!this.accessoryData.hasOwnProperty('onValue'))
                    this.accessoryData.onValue = this.onValue

                if (!this.accessoryData.hasOwnProperty('pulse'))
                    this.accessoryData.pulse = this.pulse

                if (!this.accessoryData.hasOwnProperty('pulseDuration'))
                    this.accessoryData.pulseDuration = this.pulseDuration                
            }
            else{
                this.addressType = this.accessoryData.details.device.actions.setOn.addressType
                if(this.addressType == ADDRESS_TYPE_COIL){
                    this.coilOnValue = this.accessoryData.details.device.actions.setOn.onValue
                }
            }
        }        
    }
</script>

<style scoped>

</style>