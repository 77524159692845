<template>
  <div>
    <el-form-item prop="details.io" :label="$t('accessory.input-number')" :rules="[{required: true, message: $t('accessory.form-rules.select-input')}]">
      <el-select v-model="accessoryData.details.io" :placeholder="$t('accessory.select-input')">
        <el-option v-for="index in inputs" :label="'I' + (index + 1)" :value="index" :key="index + ''">
          <span style="font-weight: bold">{{ 'I' + (index + 1) }}</span>
          <span> {{ '(' + $t('accessory.input') + ' ' + (index + 1) + ')'}}</span>
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item prop="trippedValue" :label="$t('accessory.normally')" style="width: 400px">
      <el-switch v-model="trippedValue" :active-value="true" :inactive-value="false"></el-switch>
    </el-form-item>


    <el-form-item v-if="!isSuddenSensor()" prop="entryDelay" :label="$t('accessory.entry-delay')" style="width: 400px" :rules="[{required: true, message: $t('accessory.form-rules.enter-entry-delay')}]">
      <el-input type="number" v-model="accessoryData.entryDelay" :placeholder="$t('accessory.enter-entry-delay')">
      </el-input>
    </el-form-item>

    <el-form-item v-if="!isSuddenSensor()" prop="armStateInAwayMode" :label="$t('accessory.away')" style="width: 400px">
      <el-switch @change="changedSwitch" v-model="armStateInAwayMode"></el-switch>
    </el-form-item>

    <el-form-item v-if="!isSuddenSensor()" prop="armStateInStayMode" :label="$t('accessory.stay')" style="width: 400px">
      <el-switch v-model="armStateInStayMode"></el-switch>
    </el-form-item>
  </div>
</template>


<script>
  import {
    ACCESSORY_CATEGORY_SENSOR_DOOR,
    ACCESSORY_CATEGORY_SENSOR_MOTION,
    ACCESSORY_CATEGORY_SENSOR_WINDOW
  } from "../../../../utils/accessory-utils";

  export default {
    name: "LogoSensorInputs",

    data: function(){
      return {
        trippedValue: 'trippedValue' in this.accessoryData ? this.accessoryData.trippedValue : !this.isSuddenSensor(),
        armStateInAwayMode: 'armStateInAwayMode' in this.accessoryData ? this.accessoryData.armStateInAwayMode : true,
        armStateInStayMode: 'armStateInStayMode' in this.accessoryData ? this.accessoryData.armStateInStayMode : true,
      }
    },

    computed: {
      inputs(){
        return [...Array(24).keys()]
      },
    },

    props: {
      accessoryData: {}
    },

    methods: {
      isSuddenSensor(){
        return ![ACCESSORY_CATEGORY_SENSOR_DOOR, ACCESSORY_CATEGORY_SENSOR_WINDOW, ACCESSORY_CATEGORY_SENSOR_MOTION].includes(this.accessoryData.category)
      }
    },

    watch: {
      trippedValue: function (value) {
        this.accessoryData.trippedValue = value
      },
      armStateInAwayMode: function (value) {
        this.accessoryData.armStateInAwayMode = value
      },
      armStateInStayMode: function (value) {
        this.accessoryData.armStateInStayMode = value
      },
    },



    created(){
      this.accessoryData.armStateInDisarmMode = this.isSuddenSensor()
      if (!this.accessoryData.hasOwnProperty("trippedValue"))
        this.accessoryData.trippedValue = this.trippedValue
      if (!this.accessoryData.hasOwnProperty("armStateInAwayMode"))
        this.accessoryData.armStateInAwayMode = this.armStateInAwayMode
      if (!this.accessoryData.hasOwnProperty("armStateInStayMode"))
        this.accessoryData.armStateInStayMode = this.armStateInStayMode

      console.log(this.accessoryData)
    }
  }
</script>

<style scoped>

</style>